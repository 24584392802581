define("frontend/pods/components/report-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "9JKDQ/8H",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"col-sm-4 col-md-3\"],[13],[0,\"\\n    \"],[11,\"p\",[]],[13],[0,\" \"],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"thumbnail\"],[13],[0,\"\\n        \"],[11,\"img\",[]],[15,\"width\",\"100%\"],[15,\"src\",\"/assets/images/temp/upload.jpg\"],[15,\"alt\",\"\"],[13],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"caption\"],[13],[0,\"\\n            \"],[11,\"h5\",[]],[13],[0,\"Haugesund – 01.03.2016\"],[14],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"Bjørn Bjørnsen\"],[14],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"Lorem ipsum solor\"],[14],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"canChange\"]]],null,{\"statements\":[[0,\"                    \"],[11,\"a\",[]],[15,\"class\",\"btn btn-primary\"],[15,\"role\",\"button\"],[13],[0,\"Godkjenn\"],[14],[0,\"\\n                    \"],[6,[\"link-to\"],[\"reports.edit\"],[[\"class\"],[\"btn btn-default pull-right\"]],{\"statements\":[[0,\"Endre\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},null],[0,\"\\t\\t\\t\"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/report-card/template.hbs"
    }
  });
});