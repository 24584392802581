define("frontend/pods/components/calculator-widget/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
  function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t.return && (u = t.return(), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    layoutName: 'components/calculator-widget',
    hasNoSelection: Ember.computed('outputs.@each._selected', function () {
      return !this.get('outputs').isAny('_selected', true);
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('sharedInputs', new Ember.Object({
        'surfacetype': new Ember.Object({
          'name': 'SF type',
          'id': 'surfacetype',
          'type': 'select',
          'value': 3,
          // Make sure to update this if selected is changed below
          'options': [{
            value: 1,
            label: "Gummidekke"
          }, {
            value: 2,
            label: "Gress"
          }, {
            value: 3,
            label: "Sand",
            selected: "selected"
          },
          // Make sure to update 'value' above if this is changed
          {
            value: 4,
            label: "Grus"
          }, {
            value: 5,
            label: "Stedlige masser"
          }, {
            value: 6,
            label: "Bark"
          }, {
            value: 7,
            label: "Flis"
          }, {
            value: 8,
            label: "Jord"
          }]
        }),
        'edge': new Ember.Object({
          'name': 'Kant/plan',
          'id': 'edge',
          'type': 'select',
          'value': '',
          'options': [{
            value: 1,
            label: "Kant",
            selected: "selected"
          }, {
            value: 2,
            label: "Plan"
          }]
        }),
        'seattype': new Ember.Object({
          'name': 'Sete-type',
          'id': 'seattype',
          'type': 'select',
          'value': '',
          'options': [{
            value: 1,
            label: "Enkelt"
          }, {
            value: 3,
            label: "Stående dekk"
          }, {
            value: 4,
            label: "Gruppe"
          }]
        }),
        'seatwidth': new Ember.Object({
          'id': 'seatwidth',
          'name': 'Setefeste G',
          'type': 'number'
        }),
        'length': new Ember.Object({
          'id': 'length',
          'name': 'Oppheng h2',
          'type': 'number'
        }),
        'fallheight1': new Ember.Object({
          'id': 'fallheight1',
          'name': 'Fri fallhøyde 1',
          'type': 'number'
        }),
        'fallheight2': new Ember.Object({
          'id': 'fallheight2',
          'name': 'Fri fallhøyde 2',
          'type': 'number'
        }),
        'seatheight': new Ember.Object({
          'id': 'seatheight',
          'name': 'Setehøyde i lodd',
          'type': 'number'
        })
      }));

      // Any changes here also requires changes in models.py
      this.set('sharedOutputs', {
        'fallheight': new Ember.Object({
          id: 'fallheight',
          code: '',
          name: 'Frifallhøyde',
          value: '',
          noInputs: true
        }),
        'fallheight2': new Ember.Object({
          id: 'fallheight2',
          code: '241021',
          name: 'SF lengde',
          value: '',
          measurement1: new Ember.Object({
            name: 'foran',
            value: ''
          }),
          measurement2: new Ember.Object({
            name: 'bak',
            value: ''
          })
        }),
        '142824': new Ember.Object({
          id: '142824',
          code: '142824',
          name: 'SF utbredelse',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '142824a': new Ember.Object({
          id: '142824a',
          code: '142824',
          name: 'SF utbredelse 1',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '142824b': new Ember.Object({
          id: '142824b',
          code: '142824',
          name: 'SF utbredelse 2',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '241022': new Ember.Object({
          id: '241022',
          code: '241022',
          name: 'SF bredde',
          value: '63',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '14285Dybde': new Ember.Object({
          id: '14285Dybde',
          code: '14285Dybde',
          showWhen: new Ember.Object({
            input: 'surfacetype',
            values: [3, 4, 5, 6, 7]
          }),
          name: 'SF dybde',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '2420': new Ember.Object({
          id: '2420',
          code: '2420',
          name: 'Bakkeklaring h4',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '2441StativKlaring': new Ember.Object({
          id: '2441StativKlaring',
          code: '2441StativKlaring',
          name: 'Stativklaring C',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '2441SeteRom': new Ember.Object({
          id: '2441SeteRom',
          code: '2441SeteRom',
          name: 'Seterom S',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '2442': new Ember.Object({
          id: '2442',
          code: '2442',
          name: 'Avstand topp F',
          value: '',
          constant: 0.05,
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '14284HiS': new Ember.Object({
          id: '14284HiS',
          code: '14284HiS',
          name: 'Hindring i FS',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '14284HiSHu': new Ember.Object({
          id: '14284HiSHu',
          code: '14284HiSHu',
          name: 'Hindring i FS',
          value: '',
          measurement1: new Ember.Object({
            name: 'målt',
            value: ''
          })
        }),
        '3480HiS': new Ember.Object({
          id: '3480HiS',
          code: '3480HiS',
          name: 'Hindring i FS (RB)',
          value: "200/100",
          valueArray: [100, 200],
          measurement1: new Ember.Object({
            name: 'side',
            value: ''
          }),
          measurement2: new Ember.Object({
            name: 'foran',
            value: ''
          })
        }),
        '3480HiS150': new Ember.Object({
          id: '3480HiS150',
          code: '3480HiS',
          name: 'Hindring i FS (RB)',
          value: "150/100",
          valueArray: [100, 150],
          measurement1: new Ember.Object({
            name: 'side',
            value: ''
          }),
          measurement2: new Ember.Object({
            name: 'foran',
            value: ''
          })
        }),
        '14285Kvalitet': new Ember.Object({
          id: '14285Kvalitet',
          code: '14285Kvalitet',
          name: 'SF kvalitet',
          value: false,
          extra: true
        }),
        '142857': new Ember.Object({
          id: '142857',
          code: '142857',
          name: 'SF FDV-avvik',
          value: false,
          extra: true
        }),
        'LF114': new Ember.Object({
          id: 'LF114',
          code: 'LF114',
          name: 'Ikke tillatt SF',
          value: false,
          extra: true
        }),
        '3480SfUtbr': new Ember.Object({
          id: '3480SfUtbr',
          code: '3480SfUtbr',
          name: 'SF utbredelse RB',
          value: '200/100',
          valueArray: [100, 200],
          measurement1: new Ember.Object({
            name: 'side',
            value: ''
          }),
          measurement2: new Ember.Object({
            name: 'foran',
            value: ''
          })
        }),
        '3480SfUtbr150': new Ember.Object({
          id: '3480SfUtbr150',
          code: '3480SfUtbr',
          name: 'SF utbredelse RB',
          value: '150/100',
          valueArray: [100, 150],
          measurement1: new Ember.Object({
            name: 'side',
            value: ''
          }),
          measurement2: new Ember.Object({
            name: 'foran',
            value: ''
          })
        })
      });
    },
    didInsertElement: function didInsertElement() {
      this.updateCanSelect();
      this.updateVisibility();
      this.set('extraOutputs', this.get('outputs').filterBy('extra', true));
      this.set('noInputsOutputs', this.get('outputs').filterBy('noInputs', true));
    },
    getInputValue: function getInputValue(id) {
      return parseFloat(this.get('inputs').findBy('id', id).get('value'), 10);
    },
    getOutputField: function getOutputField(id, field) {
      return this.get('outputs').findBy('id', id).get(field);
    },
    setResultValue: function setResultValue(id, value) {
      value = this.formatNumber(value);
      this.get('outputs').findBy('id', id).set('value', value);
    },
    formatNumber: function formatNumber(number) {
      if (isNaN(number)) {
        return null;
      }
      if (number == null) {
        return null;
      }
      return Math.round(number);
    },
    canCalc: function canCalc(inputs) {
      var _this = this;
      return inputs.every(function (input) {
        var value = _this.getInputValue(input);
        return value != '' && !isNaN(value);
      });
    },
    calculate: function calculate() {},
    // implment in sub type
    updateCanSelect: function updateCanSelect() {
      this.get('outputs').forEach(function (output) {
        var m1 = output.get('measurement1');
        var m2 = output.get('measurement2');
        var value = output.get('value');
        if (output.get('extra')) {
          output.set('_canSelect', true);
        } else if (m2) {
          var mvalue1 = m1.get('value');
          var mvalue2 = m2.get('value');
          var value1 = value;
          var value2 = value;
          if (output.get('valueArray')) {
            var _output$get = output.get('valueArray');
            var _output$get2 = _slicedToArray(_output$get, 2);
            value1 = _output$get2[0];
            value2 = _output$get2[1];
          }
          var selected = value1 && mvalue1 && mvalue1 < value1 || value2 && mvalue2 && mvalue2 < value2;
          output.set('_selected', selected);
          output.set('_canSelect', selected);
        } else if (m1 && value === false) {
          var _mvalue = m1.get('value');
          output.set('_canSelect', _mvalue != '');
          output.set('_selected', _mvalue != '');
        } else if (m1) {
          var _mvalue2 = m1.get('value');
          output.set('_selected', _mvalue2 && value && _mvalue2 < value);
          output.set('_canSelect', output.get('_selected'));
        } else {
          output.set('_canSelect', value != '');
        }
      });
    },
    updateVisibility: function updateVisibility() {
      var _this2 = this;
      this.get('outputs').forEach(function (output) {
        var showWhen = output.get('showWhen');
        if (!showWhen) {
          output.set('_visible', true);
          return;
        }
        var input = _this2.get('inputs').findBy('id', showWhen.get('input'));
        var value = parseInt(input.get('value'), 10);
        output.set('_visible', showWhen.get('values').indexOf(value) >= 0);
      });
    },
    actions: {
      inputChanged: function inputChanged(id, value) {
        this.get('inputs').findBy('id', id).set('value', value);
        this.calculate();
        this.updateVisibility();
        this.updateCanSelect();
      },
      outputChanged: function outputChanged() {
        this.updateCanSelect();
      },
      addDeviations: function addDeviations() {
        var selectedOutputs = this.get('outputs').filterBy('_selected', true);
        var data = [];
        selectedOutputs.forEach(function (output) {
          var input1 = output.get('measurement1.value');
          var input2 = output.get('measurement2.value');
          var value = output.get('value');
          var code = output.get('code');
          if (code instanceof Array) {
            code.forEach(function (code) {
              data.push({
                value: value,
                code: code,
                input1: input1,
                input2: input2
              });
            });
          } else {
            data.push({
              value: value,
              code: code,
              input1: input1,
              input2: input2
            });
          }
        });
        this.get('onAddDeviations')(data);
        this.get('outputs').setEach('_selected', false);
      }
    }
  });
});