define("frontend/pods/components/drive-list-table/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "GbhnPRN0",
    "block": "{\"statements\":[[6,[\"crud-list\"],null,[[\"showRefresh\",\"showSearch\",\"data\"],[[28,[\"showRefresh\"]],true,[28,[\"locations\"]]]],{\"statements\":[[6,[\"if\"],[[33,[\"eq\"],[[28,[\"context\",\"section\"]],\"toolbar-right\"],null]],null,{\"statements\":[[6,[\"if\"],[[28,[\"onCopy\"]]],null,{\"statements\":[[0,\"            \"],[11,\"button\",[]],[16,\"disabled\",[33,[\"if\"],[[28,[\"context\",\"hasSelection\"]],false,true],null],null],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[28,[\"context\",\"proxy\"]],[28,[\"onCopy\"]]],null]],null],null],[15,\"type\",\"button\"],[15,\"class\",\"btn btn-sm btn-success\"],[13],[0,\"\\n                \"],[11,\"span\",[]],[15,\"class\",\"glyphicon glyphicon-duplicate\"],[13],[14],[0,\" Opprett rapporter\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[]},null],[6,[\"if\"],[[33,[\"eq\"],[[28,[\"context\",\"section\"]],\"main\"],null]],null,{\"statements\":[[6,[\"each\"],[[28,[\"context\",\"data\"]]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"class\",\"col-sm-12\"],[13],[0,\"\\n                \"],[1,[33,[\"location-line\"],null,[[\"location\"],[[28,[\"l\"]]]]],false],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[\"l\"]},{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"class\",\"col-sm-12\"],[13],[0,\"\\n                \"],[11,\"p\",[]],[15,\"class\",\"empty-message\"],[13],[0,\"Ingen lokasjoner\"],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null]],\"locals\":[\"context\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/drive-list-table/template.hbs"
    }
  });
});