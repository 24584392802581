define("frontend/pods/components/calculator-std/component", ["exports", "frontend/pods/components/calculator-widget/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var sharedInputs = this.get('sharedInputs');
      var sharedOutputs = this.get('sharedOutputs');
      this.set('inputs', [sharedInputs['surfacetype'], new Ember.Object({
        'id': 'fallheight',
        'name': 'Fri fallhøyde',
        'type': 'number'
      })]);

      // Any changes here also requires changes in models.py
      this.set('outputs', [sharedOutputs['142824'], sharedOutputs['3480SfUtbr'], sharedOutputs['3480SfUtbr150'], sharedOutputs['14285Kvalitet'], sharedOutputs['14285Dybde'], sharedOutputs['142857'], sharedOutputs['LF114'], sharedOutputs['14284HiS'], sharedOutputs['3480HiS'], sharedOutputs['3480HiS150']]);
    },
    calculate: function calculate() {
      var _this = this;
      this.setResultValue('142824', null);
      this.setResultValue('14285Dybde', null);
      var calcFallheight = function calcFallheight(input) {
        var fallheight = _this.getInputValue(input);
        return fallheight > 150 ? fallheight * (2 / 3) + 50 : 150;
      };
      if (this.canCalc(['fallheight'])) {
        var fallheightResult = calcFallheight('fallheight');
        this.setResultValue('142824', fallheightResult);
        this.setResultValue('14284HiS', fallheightResult); // Comes from hisA above
        this.setResultValue('14285Dybde', this.getInputValue('fallheight') <= 200 ? 30 : 40);
      }
    }
  });
});