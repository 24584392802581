define("frontend/pods/reports/customer/contactinfo/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    feedback: Ember.inject.service('feedback'),
    spinner: Ember.inject.service('spinner'),
    errors: [],
    actions: {
      save: function save() {
        var _this = this;
        this.set('errors', []);
        this.get('spinner').show('main');
        this.get('model.contact').save({
          is_customer: true
        }).then(function () {
          var message = "Kontaktinformasjon ble oppdatert";
          _this.get('feedback').show('contactinfo', 'success', message, "Lagring utført");
          _this.get('spinner').hide('main');
        }).catch(function (error) {
          _this.set('errors', error.errors);
          _this.get('feedback').show('contactinfo', 'danger', "", "Lagring feilet");
          _this.get('spinner').hide('main');
        });
      }
    }
  });
});