define("frontend/pods/components/calculator-onepointswing/component", ["exports", "frontend/pods/components/calculator-oneseatswing/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var sharedInputs = this.get('sharedInputs');
      this.set('inputs', Ember.A([sharedInputs['surfacetype'], sharedInputs['edge'], sharedInputs['seattype'], sharedInputs['length']]));
      var sharedOutputs = this.get('sharedOutputs');
      var HiFS = sharedOutputs['14284HiSHu'];
      HiFS['helpText'] = 'I alle retninger';
      this.set('outputs', Ember.A([sharedOutputs['fallheight'], sharedOutputs['fallheight2'], sharedOutputs['241022'], sharedOutputs['14285Dybde'], sharedOutputs['2420'], sharedOutputs['2442'], sharedOutputs['14285Kvalitet'], sharedOutputs['142857'], sharedOutputs['LF114'], HiFS]));
      this.get('inputs').findBy('id', 'seattype').set('options', [{
        value: 1,
        label: "Enkelt"
      }, {
        value: 3,
        label: "Stående dekk"
      }, {
        value: 4,
        label: "Gruppe",
        selected: "selected"
      }]);
      this.get('inputs').findBy('id', 'seattype').set('value', 4);

      // Any changes here also requires changes in models.py
      var outputs = this.get('outputs');
      outputs.findBy('code', '2420').set('value', 40);
      outputs.findBy('code', '2420').set('code', '2420Fhu');
      outputs.findBy('code', '241021').set('code', '241023EttPHu');
      outputs.removeObject(outputs.findBy('code', '241022'));
      outputs.removeObject(outputs.findBy('code', '2442'));
      this.set('outputs', outputs);
    },
    calculate: function calculate() {
      this.setResultValue('fallheight', null);
      this.setResultValue('fallheight2', null);
      this.setResultValue('14285Dybde', null);
      if (this.canCalc(['length'])) {
        var length = this.getInputValue('length');
        this.setResultValue('fallheight', length * 0.867);
        this.setResultValue('14285Dybde', length * 0.867 <= 200 ? 30 : 40);
        if (this.canCalc(['edge'])) {
          var edge = this.getInputValue('edge');
          var edge_value = edge == 2 ? 175 : 225;
          this.setResultValue('fallheight2', length * 0.867 + edge_value);
          this.setResultValue('14284HiSHu', length * 0.867 + edge_value);
        }
      }
      if (this.canCalc(['seattype'])) {
        var seattype = this.getInputValue('seattype');
        var value = '';
        if (seattype == 1) {
          value = 35;
        } else if (seattype == 2 || seattype == 4) {
          value = 40;
        } else if (seattype == 3) {
          value = 10;
        }
        this.setResultValue('2420', value);
      }
    }
  });
});