define("frontend/pods/main/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin", "ember-simple-auth/mixins/application-route-mixin"], function (_exports, _authenticatedRouteMixin, _applicationRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend(_applicationRouteMixin.default, _authenticatedRouteMixin.default, {
    user: Ember.inject.service('user'),
    session: Ember.inject.service('session'),
    sync: Ember.inject.service('sync'),
    compatibility: Ember.inject.service('compatibility'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (this.get('compatibility').isOk()) {
        if (this.get('session').get('isAuthenticated')) {
          this.get('user').getCurrent();
        }
      } else {
        return this.transitionTo('not-supported');
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      this.get('user').invalidateSession().then(function () {
        window.location.replace('');
      });
    }
  });
});