define("frontend/pods/reports/edit/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    user: Ember.inject.service('user'),
    spinner: Ember.inject.service('spinner'),
    model: function model(params) {
      var _this = this;
      var isAdmin = new Ember.RSVP.Promise(function (resolve) {
        _this.get('user').getCurrent().then(function (user) {
          return user.get('type');
        }).then(function (type) {
          return resolve(type.identifier == 'admin');
        });
      });
      return Ember.RSVP.hash({
        report: this.get('store').findRecord(params.model_name, params.ireport_id),
        //.then(result => result)
        //.catch(error => null),
        locations: this.get('store').findAll('ilocation'),
        manufacturers: this.get('store').findAll('imanufacturer'),
        isAdmin: isAdmin
      });
    },
    afterModel: function afterModel(data) {
      data.manufacturers = data.manufacturers.sortBy('name');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      if (!controller) return;
      controller.set('confirmedCanNavigateAway', false);
      //if (!controller.isValid()) {
      //    alert('Rapporten ble ikke funnet. Er den fjernet?');
      //    this.transitionTo('reports.index');
      //}

      // Trigger validation of the report upon entering the report edit view
      // Ember should be aware of this async operation according to this:
      // https://discuss.emberjs.com/t/is-there-an-event-after-the-template-has-been-added-to-the-outlet/8737/4
      return controller.isValid();
    },
    deactivate: function deactivate() {
      var _this2 = this;
      var data = this.controller.get('model');
      if (data.isAdmin) {
        this.get('spinner').show('main');
        return data.report.getRelatedModels('cascadeDelete').then(function (models) {
          var deletes = [];
          models.forEach(function (model) {
            if (!model.get('isDeleted')) {
              deletes.push(model.destroyRecord());
            }
          });
          deletes.push(data.report.destroyRecord());
          Ember.RSVP.all(deletes).then(function () {
            _this2.get('spinner').hide('main');
          });
        });
      }
    },
    actions: {
      willTransition: function willTransition(transition) {
        var data = this.controller.get('model');
        if (data.isAdmin) {
          if (data.report.get('hasPendingLocalChanges') && !this.controller.get('confirmedCanNavigateAway')) {
            this.controller.displayConfirmExit(transition);
            transition.abort();
          }
        }
      }
    }
  });
});