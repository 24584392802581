define("frontend/pods/location/model", ["exports", "ember-data", "frontend/pods/utils/models"], function (_exports, _emberData, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_models.default, {
    name: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    address2: _emberData.default.attr('string'),
    zip: _emberData.default.attr('string'),
    municipality: _emberData.default.attr('string'),
    modified: _emberData.default.attr('number'),
    customer: _emberData.default.belongsTo('customer'),
    contactpeople: _emberData.default.hasMany('contact-person'),
    defaultInspectionDate: _emberData.default.attr('date'),
    status: _emberData.default.attr('number'),
    reports: _emberData.default.hasMany('report'),
    county: _emberData.default.belongsTo('county'),
    detailedName: Ember.computed('name', 'municipality', function () {
      return "".concat(this.get('name'), " \u2013 ").concat(this.get('municipality'));
    }),
    lastCompletedReport: Ember.computed('reportByInspectorDate', function () {
      if (this.get('reportByInspectorDate').length > 0) {
        return [this.get('reportByInspectorDate')[0]];
      } else {
        return [];
      }
    }),
    reportByInspectorDate: Ember.computed.sort('reports', function (item1, item2) {
      return item1.get('inspectorDate') > item2.get('inspectorDate');
    }),
    fullAddress: Ember.computed('address', 'address2', 'zip', 'municipality', function () {
      var a = this.getProperties('address', 'address2', 'zip', 'municipality');
      var result = '';
      if (a.address) {
        result += a.address + "<br>";
      }
      if (a.address2) {
        result += a.address2 + "<br>";
      }
      if (a.zip && a.municipality) {
        result += "".concat(a.zip, ", ").concat(a.municipality) + "<br>";
      } else if (a.zip) {
        result += a.zip + "<br>";
      } else if (a.municipality) {
        result += a.municipality + "<br>";
      }
      return result;
    }),
    fullAddressOneLine: Ember.computed('address', 'address2', 'zip', 'municipality', function () {
      return Ember.A([this.get('address'), this.get('address2'), this.get('zip'), this.get('municipality')]).compact().filter(function (i) {
        return i != '';
      }).join(", ");
    })
  });
});