define("frontend/pods/utils/recursive-embed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    serialize: function serialize(snapshot, options) {
      if (options.recursive || snapshot._doRecursive) {
        snapshot._doRecursive = true;
        var attrs = {};
        snapshot.eachRelationship(function (name, relationship) {
          if (relationship.kind === "hasMany") {
            var relItems = snapshot.hasMany(relationship.key);
            if (relItems) {
              snapshot.hasMany(relationship.key).forEach(function (snap) {
                snap._doRecursive = true;
              });
              attrs[name] = {
                serialize: 'records'
              };
            }
          }
          //if (relationship.kind === "belongsTo") {
          //let snap = snapshot.belongsTo(relationship.key);
          //if (snap) { snap._doRecursive = true; }
          //}
        });
        this.set('attrs', attrs);
      } else {
        this.set('attrs', {});
      }
      return this._super.apply(this, arguments);
    }
  });
});