define("frontend/pods/components/entity-picker/component", ["exports", "frontend/pods/utils/search-dialog"], function (_exports, _searchDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_searchDialog.default, {
    classNames: ['picker', 'entity-picker'],
    modelName: 'ientityType',
    equalHeightSelector: '.item-thumbnail h3',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pageSize', -1); // disable pagination
    }
  });
});