define("frontend/pods/deviation-type/model", ["exports", "ember-data", "frontend/pods/utils/models"], function (_exports, _emberData, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_models.default, {
    name: _emberData.default.attr('string'),
    modified: _emberData.default.attr('number'),
    description: _emberData.default.attr('string'),
    code: _emberData.default.attr('string'),
    legalBasisCode: _emberData.default.attr('string'),
    helpText: _emberData.default.attr('string'),
    searchWord: _emberData.default.attr('string'),
    action: _emberData.default.attr('string'),
    grade: _emberData.default.attr('string'),
    images: _emberData.default.hasMany('image'),
    texts: _emberData.default.hasMany('deviationTypeText'),
    status: _emberData.default.attr('number'),
    importantInfo: _emberData.default.attr('string'),
    image: Ember.computed('images.[]', {
      get: function get() {
        return this.get('images.firstObject');
      },
      set: function set(key, value) {
        this.set('images', []);
        this.get('images').pushObject(value);
        return value;
      }
    })
  });
});