define("frontend/pods/reports/customer/completed/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    queryParams: {
      page: {
        refreshModel: true
      },
      inspector: {
        refreshModel: true
      },
      customer: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      },
      notified: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var reportQuery = Ember.$.extend({
        is_customer: 1,
        include: 'location,images,location.customer,inspector,contact',
        page_size: 50
      }, params);
      return Ember.RSVP.hash({
        params: params,
        reports: this.store.query('report', reportQuery)
      });
    },
    afterModel: function afterModel(model) {
      model.sort = [new Ember.Object({
        "id": "alpha",
        "name": "Alfabetisk (A-Å)"
      }), new Ember.Object({
        "id": "inspection_date",
        "name": "Inspeksjonsdato (eldst først)"
      }), new Ember.Object({
        "id": "inspection_date_desc",
        "name": "Inspeksjonsdato (nyest først)"
      }), new Ember.Object({
        "id": "not_read",
        "name": "Ikke lest (eldst først)"
      }), new Ember.Object({
        "id": "not_read_desc",
        "name": "Ikke lest (nyest først)"
      })];
      model.years = [];
      model.currentYear = new Date().getFullYear().toString();
      var years = new Date().getFullYear() - 2017;
      for (var year = 2017; year <= 2017 + years; year++) {
        model.years.push(new Ember.Object({
          "id": String(year),
          "name": String(year)
        }));
      }
    }
  });
});