define("frontend/pods/components/calculator-twoseatswing/component", ["exports", "frontend/pods/components/calculator-oneseatswing/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      var sharedOutputs = this.get('sharedOutputs');
      // Any changes here also requires changes in models.py
      this.set('outputs', [sharedOutputs['fallheight'], sharedOutputs['fallheight2'], sharedOutputs['241022'], sharedOutputs['14285Dybde'], sharedOutputs['2420'], sharedOutputs['2441StativKlaring'], sharedOutputs['2441SeteRom'], sharedOutputs['2442'], sharedOutputs['14285Kvalitet'], sharedOutputs['142857'], sharedOutputs['LF114'], sharedOutputs['14284HiSHu']]);
      this.get('inputs').findBy('id', 'seattype').set('options', [{
        value: 1,
        label: "Enkelt",
        selected: "selected"
      }, {
        value: 3,
        label: "Stående dekk"
      }]);
      this.get('inputs').findBy('id', 'seattype').set('value', 1);
    },
    calculate: function calculate() {
      this._super.apply(this, arguments);
      this.setResultValue('2441SeteRom', null);
      if (this.canCalc(['length'])) {
        var length = this.getInputValue('length');
        this.setResultValue('2441SeteRom', length * 0.2 + 30);
      }
    }
  });
});