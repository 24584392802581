define("frontend/pods/components/report-preview/component", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    download: Ember.inject.service('download'),
    previewItems: [],
    show: false,
    createPreviewItem: function createPreviewItem(report) {
      return {
        id: report.get('id'),
        name: report.get('location.name') || "Ukjent lokasjon",
        link: "".concat(_environment.default.APP.jsonApiHost, "/report/").concat(report.get('id'))
      };
    },
    actions: {
      load_iframe: function load_iframe(id, single) {
        this.get('download').getReportPreview(id).then(function (response) {
          var iframeRef = single ? '#single_iframe_report_' : '#iframe_report_';
          var iframeElement = document.querySelector("".concat(iframeRef).concat(id));
          var content = iframeElement.contentWindow || iframeElement.contentDocument.document || iframeElement.contentDocument;
          content.document.open();
          content.document.write(_environment.default.APP.jsonApiHost.includes('localhost') ? response.replace(/\/var\//g, "".concat(_environment.default.APP.jsonApiHost, "/var/")).replace(/\/static\//g, "".concat(_environment.default.APP.jsonApiHost, "/static/")) : response);
          content.document.close();
        });
      },
      show: function show() {
        var _this = this;
        this.set('previewItem', null);
        this.set('previewItems', []);
        var items = [];
        var reports = this.get('reports');
        if (reports) {
          reports.forEach(function (report) {
            items.push(_this.createPreviewItem(report));
            report.set('isRead', true);
          });
          this.set('previewItems', items);
        } else {
          var report = this.get('report');
          this.set('previewItem', this.createPreviewItem(report));
          report.set('isRead', true);
        }
        this.set('show', true);
      },
      close: function close() {
        this.set('previewItem', null);
        this.set('previewLinks', []);
        this.set('show', false);
      }
    }
  });
});