define("frontend/pods/reports/admin/pending/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    feedback: Ember.inject.service('feedback'),
    sync: Ember.inject.service('sync'),
    spinner: Ember.inject.service('spinner'),
    selectedReports: Ember.computed('model.reports.@each._selected', function () {
      return this.get('model.reports').filterBy('_selected');
    }),
    queryParams: ['page', 'inspector', 'customer', 'sort'],
    page: 1,
    inspector: null,
    customer: null,
    sort: 'inspection_date',
    actions: {
      edit: function edit(report) {
        var _this = this;
        this.get('spinner').show('main');
        return this.get('sync').syncReport(report).then(function () {
          _this.get('spinner').hide('main');
          return _this.transitionToRoute('reports.edit', report.get('id'), 'ireport');
        });
      },
      complete: function complete(reports) {
        var _this2 = this;
        var spinner = this.get('spinner');
        this.set('completeProgressText', 'Genererer PDFer. Dette kan ta noe tid');
        var setProgress = function setProgress(completed) {
          _this2.set('completeProgressText2', completed + ' av ' + reports.length + ' ferdig');
        };
        setProgress(0);
        spinner.show('complete-progress');
        var promise = new Ember.RSVP.Promise(function (resolve) {
          resolve();
        });
        reports.forEach(function (report, index) {
          promise = promise.then(function () {
            setProgress(index);
            var id = report.get('id');
            // append id to url only so that it appears in nginx access
            // logs for easier debugging.
            return _this2.get('ajax').jsonRequest('/reports/complete?report_id=' + id, [id]);
          });
        });
        promise.then(function () {
          var message = "Alle rapport(er) ble ferdigstilt.";
          _this2.get('feedback').show('crud-list', 'success', message, "Ferdigstilling utført");
          reports.setEach('_selected', false);
          _this2.get('model').reports.update().then(function () {
            spinner.hide('complete-progress');
          });
        });
      },
      showPreview: function showPreview() {
        this.toggleProperty('showPreviewDialog');
      },
      closePreview: function closePreview() {
        this.toggleProperty('showPreviewDialog');
      },
      setFilter: function setFilter(field, obj) {
        var value = null;
        if (obj) {
          value = obj.get('id');
        }
        this.set(field, value);
      }
    }
  });
});