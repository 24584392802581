define("frontend/pods/utils/remoteid", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /*
   * Model mixin to support storing ids to records that exists in other adapters.
   * Ember data adapters generally asks itself for related data which means you
   * can't related to records with each other that uses two different adapters.
   * This works around that by storing the ID of the relation in a json string
   * and looks it up via computed properties on the model. This way we can have
   * a model stored in local storage that refers to a model stored in indexeddb.
  */
  var _default = _exports.default = Ember.Mixin.create({
    _store: Ember.inject.service('store'),
    _getRemoteRecord: function _getRemoteRecord(remoteIdKey, modelName) {
      var _this = this;
      var promise = new Ember.RSVP.Promise(function (resolve, reject) {
        var ids = _this.get(remoteIdKey);
        if (ids) {
          ids = JSON.parse(ids);
          if (ids.length > 0) _this.get('_store').findRecord(modelName, ids[0]).then(function (model) {
            return resolve(model);
          }).catch(function () {
            return reject(null);
          });
        } else {
          reject(null);
        }
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    },
    _setRemoteRecord: function _setRemoteRecord(remoteIdKey, key, value) {
      if (value === null) {
        var model = this.get(remoteIdKey);
        if (model && model.destroyRecord) {
          model.destroyRecord();
        }
        if (model && model.content && model.content.destroyRecord) {
          model.content.destroyRecord();
        }
        this.set(remoteIdKey, JSON.stringify([]));
        return;
      }
      var ids = [value.get('id')];
      this.set(remoteIdKey, JSON.stringify(ids));
      return value;
    },
    _getRemoteRecords: function _getRemoteRecords(remoteIdKey, modelName) {
      var _this2 = this;
      var promises = [];
      var ids = this.get(remoteIdKey);
      if (ids) {
        ids = JSON.parse(ids);
        ids.forEach(function (id) {
          promises.push(_this2.get('_store').findRecord(modelName, id));
        });
      } else {
        promises.push(new Ember.RSVP.Promise(function (resolve, reject) {
          reject(null);
        }));
      }
      return _emberData.default.PromiseArray.create({
        promise: Ember.RSVP.all(promises).then(function (data) {
          return data;
        })
      });
    },
    _setRemoteRecords: function _setRemoteRecords(imageIdKey, key, value) {
      var ids = value.getEach('id');
      this.set(imageIdKey, JSON.stringify(ids));
      return _emberData.default.PromiseArray.create({
        promise: new Ember.RSVP.Promise(function (resolve) {
          resolve(value);
        })
      });
    }
  });
});