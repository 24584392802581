define("frontend/pods/components/error-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "r7Pf9Dl6",
    "block": "{\"statements\":[[6,[\"each\"],[[33,[\"-each-in\"],[[28,[\"simple_errors\"]]],null]],null,{\"statements\":[[0,\"    \"],[11,\"p\",[]],[15,\"class\",\"text-danger\"],[13],[1,[28,[\"error\"]],false],[14],[0,\"\\n\"]],\"locals\":[\"key\",\"error\"]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/error-list/template.hbs"
    }
  });
});