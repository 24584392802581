define("frontend/pods/main-loading/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "URRWoz36",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"main-loading\"],[13],[0,\"\\n\\t\"],[11,\"div\",[]],[15,\"class\",\"loader\"],[13],[0,\"Loading...\"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/main-loading/template.hbs"
    }
  });
});