define("frontend/pods/user/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/VABsMcu",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"container user-form\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"row\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4\"],[13],[0,\"\\n            \"],[11,\"h1\",[]],[15,\"class\",\"text-center form-title\"],[13],[0,\"Lekeplasskontrollen\"],[14],[0,\"\\n                \"],[1,[26,[\"outlet\"]],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\\n    \"],[11,\"br\",[]],[13],[14],[0,\"\\n    \"],[11,\"div\",[]],[15,\"style\",\"text-align: center; font-size: x-large;\"],[13],[0,\"\\n      \"],[11,\"b\",[]],[13],[0,\"Lekeplasskontrollen har fått ny og oppgradert kundeportal.\"],[14],[11,\"br\",[]],[13],[14],[0,\"\\n      \"],[11,\"span\",[]],[15,\"style\",\"color: red\"],[13],[0,\"Vennligst benytt\"],[14],[0,\" \"],[11,\"a\",[]],[15,\"href\",\"https://kunde.lekeplasskontrollen.no\"],[13],[0,\"kunde.lekeplasskontrollen.no\"],[14],[0,\" \"],[11,\"span\",[]],[15,\"style\",\"color: red\"],[13],[0,\"ved innlogging.\"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/user/template.hbs"
    }
  });
});