define("frontend/pods/download/service", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    session: Ember.inject.service(),
    getReportPreview: function getReportPreview(id) {
      return fetch("".concat(_environment.default.APP.jsonApiHost, "/report/").concat(id), {
        method: 'GET',
        headers: new Headers({
          'Authorization': "Token ".concat(this.get('session').get('data.authenticated.token')),
          'Content-Type': 'text/html; charset=utf-8'
        })
      }).then(function (response) {
        return response.text();
      });
    },
    getReport: function getReport(id, attachment) {
      var baseUrl = "".concat(_environment.default.APP.jsonApiHost, "/report/").concat(id, "/download");
      var url = attachment || false ? baseUrl + "?attachment=1" : baseUrl;
      return fetch(url, {
        method: 'GET',
        headers: new Headers({
          "Authorization": "Token ".concat(this.get('session').get('data.authenticated.token'))
        })
      }).then(function (response) {
        return response.blob();
      });
    }
  });
});