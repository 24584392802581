define("frontend/pods/reports/inspector/drafts/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "shnHrVdv",
    "block": "{\"statements\":[[1,[33,[\"report-table\"],null,[[\"reports\",\"canEdit\",\"editModelName\",\"onDelete\",\"onSign\"],[[28,[\"sortedIReports\"]],true,\"ireport\",[33,[\"action\"],[[28,[null]],\"delete\"],null],[33,[\"action\"],[[28,[null]],\"sign\"],null]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/reports/inspector/drafts/template.hbs"
    }
  });
});