define("frontend/pods/reports/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    sync: Ember.inject.service('sync'),
    user: Ember.inject.service('user'),
    model: function model() {
      var _this = this;
      this._super.apply(this, arguments);
      return this.get('user').getCurrent().then(function (user) {
        return user.get('type');
      }).then(function (type) {
        if (['admin', 'inspector'].includes(type.identifier)) {
          return _this.get('sync').start();
        }
      });
    }
  });
});