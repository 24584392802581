define("frontend/pods/not-supported/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "p0rHb4vO",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"container user-form\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"row\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"col-sm-6 col-sm-offset-3 col-md-4 col-md-offset-4\"],[13],[0,\"\\n            \"],[11,\"h1\",[]],[15,\"class\",\"text-center form-title\"],[13],[0,\"Lekeplasskontrollen\"],[14],[0,\"\\n                \"],[11,\"div\",[]],[15,\"class\",\"account-wall\"],[13],[0,\"\\n                    \"],[11,\"form\",[]],[15,\"class\",\"register\"],[13],[0,\"\\n                        \"],[11,\"h2\",[]],[13],[0,\"Ikke støttet nettleser\"],[14],[0,\"\\n                        \"],[11,\"p\",[]],[13],[0,\"Din nettleser støtter dessverre ikke funksjonalitet som dette systemet trenger.\"],[14],[0,\"\\n                        \"],[11,\"p\",[]],[13],[0,\"Vi anbefaler å benytte siste versjon av Google Chrome (\"],[11,\"a\",[]],[15,\"href\",\"https://www.google.com/chrome/browser/desktop/index.html\"],[13],[0,\"last ned her\"],[14],[0,\").\"],[14],[0,\"\\n                        \"],[11,\"p\",[]],[13],[0,\"Microsoft Edge/Internet Explorer 11, siste versjon av Firefox og Safari fungerer også.\"],[14],[0,\"\\n                        \"],[11,\"a\",[]],[15,\"href\",\"/\"],[15,\"class\",\"btn btn-default btn-sm\"],[13],[0,\"Test på nytt\"],[14],[0,\"\\n                    \"],[14],[0,\"\\n                \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/not-supported/template.hbs"
    }
  });
});