define("frontend/pods/store/service", ["exports", "ember-data", "frontend/pods/utils/light-model"], function (_exports, _emberData, _lightModel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Store.extend({
    createIfNotExists: function createIfNotExists(modelName, data) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve) {
        _this.findRecord(modelName, data['id']).then(function (record) {
          return resolve(record);
        }).catch(function () {
          _this.unloadAll(modelName); // ensure any store caches are empty
          return resolve(_this.createRecord(modelName, data));
        });
      });
    },
    createOrUpdateInternalCopy: function createOrUpdateInternalCopy(model) {
      var iModelName = this._internalModelName(model);
      var data = model.toJSON({
        includeId: true
      });
      return this.push(this.normalize(iModelName, data));
    },
    createInternalCopyIfNotExists: function createInternalCopyIfNotExists(model) {
      var modelName = this.internalModelName(model.constructor.modelName);
      var data = model.toJSON({
        includeId: true
      });
      return this.createIfNotExists(modelName, data);
    },
    deleteLocalIfExists: function deleteLocalIfExists(model) {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve) {
        var imodelName = _this2._internalModelName(model);
        _this2.findRecord(imodelName, model.get('id'), {
          reload: true
        }).then(function (record) {
          return resolve(record);
        }).catch(function () {
          var ref = _this2.recordForId(imodelName, model.get('id'));
          ref.unloadRecord();
          return resolve();
        });
      });
    },
    internalModelName: function internalModelName(externalModelName) {
      return "i" + externalModelName;
    },
    findAllKeys: function findAllKeys(model) {
      return this.adapterFor(model).findAllKeys(this, this.modelFor(model));
    },
    deleteRecordById: function deleteRecordById(model, id) {
      return this.adapterFor(model).deleteRecordById(this, this.modelFor(model), id);
    },
    findMaxValue: function findMaxValue(model, field, direction) {
      return this.adapterFor(model).findMaxValue(this, this.modelFor(model), field, direction);
    },
    lfindRecord: function lfindRecord(model, id) {
      var _this3 = this;
      id = String(id);
      return this.adapterFor(model).findRecord(this, this.modelFor(model), id).then(function (record) {
        return new _lightModel.default(_this3, _this3.normalize(model, record));
      });
    },
    lfindAll: function lfindAll(model) {
      var _this4 = this;
      var rv = [];
      var records = this.adapterFor(model).findAll(this, this.modelFor(model));
      return records.then(function (records) {
        records.forEach(function (record) {
          rv.push(new _lightModel.default(_this4, _this4.normalize(model, record)));
        });
        return rv;
      });
    },
    lfindAllIds: function lfindAllIds(model, ids) {
      var _this5 = this;
      var promises = [];
      ids.forEach(function (id) {
        promises.push(_this5.lfindRecord(model, id));
      });
      return Ember.RSVP.all(promises);
    },
    _internalModelName: function _internalModelName(model) {
      return "i" + model.constructor.modelName;
    }
  });
});