define("frontend/pods/components/customer-report-line/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      markAsRead: function markAsRead(report) {
        report.set('isRead', true);
      }
    }
  });
});