define("frontend/pods/components/select-dropdown/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },
    actions: {
      change: function change(event) {
        var _this = this;
        var selectedValue;
        if (this.get('multiple')) {
          var emptyValueObject;
          var selectedValueIds = Ember.$(event.target).val() || [];
          if (selectedValueIds.includes("n/a")) {
            selectedValueIds = selectedValueIds.removeObject("n/a");
            var key = this.get('optionValuePath');
            var label = this.get('optionLabelPath');
            emptyValueObject = new Ember.Object({});
            emptyValueObject[key] = "";
            emptyValueObject[label] = "n/a";
          }
          selectedValue = selectedValueIds.map(function (id) {
            return _this.get('content').findBy(_this.get('optionValuePath'), id);
          });
          if (emptyValueObject) {
            selectedValue.insertAt(0, emptyValueObject);
          }
        } else {
          selectedValue = Ember.$(event.target).val();
          selectedValue = this.get('content').findBy(this.get('optionValuePath'), selectedValue);
        }
        this.set('selectedValue', selectedValue);
        this.get('onChange')(selectedValue);
      }
    }
  });
});