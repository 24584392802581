define("frontend/pods/user/register/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    errors: [],
    ajax: Ember.inject.service('ajax'),
    session: Ember.inject.service('session'),
    actions: {
      register: function register() {
        var _this = this;
        var data = {
          'email': this.get('email'),
          'new_password1': this.get('new_password1'),
          'new_password2': this.get('new_password2')
        };
        return this.get('ajax').jsonRequest('/register', data).then(function () {
          _this.set('errors', []);
          _this.set('success', true);
          var credentials = {
            'identification': _this.get('email'),
            'password': _this.get('new_password1')
          };
          return _this.get('session').authenticate('authenticator:token', credentials).then(function () {
            _this.set('email', null);
            _this.set('password', null);
            return _this.transitionToRoute('reports.customer');
          });
        }).catch(function (reason) {
          _this.set('success', false);
          _this.set('errors', reason.errors);
        });
      }
    }
  });
});