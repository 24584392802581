define("frontend/pods/reports/customer/contactinfo/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function model() {
      var query = {
        "is_customer": 1
      };
      return Ember.RSVP.hash({
        contact: this.store.query('contact-person', query)
      });
    },
    afterModel: function afterModel(data) {
      data.contact = data.contact.get('firstObject');
      return data;
    }
  });
});