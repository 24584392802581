define("frontend/pods/contact-person/model", ["exports", "ember-data", "frontend/pods/utils/models"], function (_exports, _emberData, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_models.default, {
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user'),
    customers: _emberData.default.hasMany('customer'),
    customer2: _emberData.default.hasMany('customer', {
      inverse: 'contactperson2'
    }),
    status: _emberData.default.attr('number'),
    modified: _emberData.default.attr('number'),
    name: Ember.computed('firstname', 'lastname', function () {
      var first = this.get('firstname') || '';
      var last = this.get('lastname') || '';
      return "".concat(last, ", ").concat(first);
    })
  });
});