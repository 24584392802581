define("frontend/pods/user/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "LcWJ7+76",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"account-wall\"],[13],[0,\"\\n    \"],[11,\"img\",[]],[15,\"class\",\"profile-img\"],[15,\"src\",\"/assets/images/login-img.jpg\"],[15,\"alt\",\"\"],[13],[14],[0,\"\\n    \"],[11,\"form\",[]],[15,\"class\",\"form-signin\"],[5,[\"action\"],[[28,[null]],\"login\"],[[\"on\"],[\"submit\"]]],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"errors\"]]],null,{\"statements\":[[0,\"            \"],[11,\"p\",[]],[15,\"class\",\"text-danger\"],[13],[1,[28,[\"error\"]],false],[14],[0,\"\\n\"]],\"locals\":[\"error\"]},null],[0,\"        \"],[1,[33,[\"input\"],null,[[\"id\",\"value\",\"type\",\"placeholder\",\"class\",\"required\",\"autofocus\"],[\"email\",[28,[\"email\"]],\"email\",\"E-post\",\"form-control\",\"true\",\"true\"]]],false],[0,\"\\n        \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"value\",\"placeholder\",\"class\",\"required\"],[\"password\",\"password\",[28,[\"password\"]],\"Passord\",\"form-control\",\"true\"]]],false],[0,\"\\n        \"],[11,\"button\",[]],[15,\"class\",\"btn btn-lg btn-primary btn-block\"],[15,\"type\",\"submit\"],[13],[0,\"Logg inn\"],[14],[0,\"\\n      \"],[6,[\"link-to\"],[\"user.password.forgot\"],[[\"class\"],[\"need-help-pw-reset\"]],{\"statements\":[[0,\"Glemt passordet?\"]],\"locals\":[]},null],[11,\"span\",[]],[15,\"class\",\"clearfix\"],[13],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"script\",[]],[13],[0,\"\\nvar current_date = new Date();\\nlocalStorage.setItem('old_date',current_date) // sets_localstorage_with_current_time\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/user/login/template.hbs"
    }
  });
});