define("frontend/pods/reports/inspector/drivelist/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "BGVA1U2O",
    "block": "{\"statements\":[[1,[33,[\"drive-list-table\"],null,[[\"locations\",\"onCopy\",\"showRefresh\"],[[28,[\"model\"]],[33,[\"action\"],[[28,[null]],\"copy\"],null],true]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/reports/inspector/drivelist/template.hbs"
    }
  });
});