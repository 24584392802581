define("frontend/pods/reports/inspector/drivelist/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    sync: Ember.inject.service('sync'),
    spinner: Ember.inject.service('spinner'),
    feedback: Ember.inject.service('feedback'),
    actions: {
      copy: function copy(locations) {
        var _this = this;
        this.get('spinner').show('main');
        return this.get('ajax').jsonRequest('/reports/copy', locations.getEach('id')).then(function (response) {
          _this.get('sync').syncReportIds(response.data).then(function () {
            return _this.get('model').update();
          }).then(function () {
            _this.get('spinner').hide('main');
            var message = "Opprettet " + locations.length + " nye rapport(er)";
            _this.get('feedback').show('crud-list', 'success', message, "Oppretting utført");
          });
        }).catch(function (error) {
          _this.get('spinner').hide('main');
          var message = "Oppretting av rapporter feilet: " + error;
          _this.get('feedback').show('crud-list', 'danger', message);
          throw error;
        });
      }
    }
  });
});