define("frontend/pods/components/select-dropdown-deviation/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }
    },
    didRender: function didRender() {
      // setTimeout is required to not break rendering of this
      setTimeout(function () {
        $('.select-dropdown-deviation').multiselect({
          buttonWidth: '100%',
          dropRight: true,
          onChange: function onChange(option) {
            if (option.context.selected && option.context.value === 'n/a') {
              $('.select-dropdown-deviation').multiselect('deselectAll');
              $('.select-dropdown-deviation').multiselect('select', 'n/a');
              $('.select-dropdown-deviation').parent().click();
            } else {
              $('.select-dropdown-deviation').multiselect('deselect', 'n/a');
            }
          },
          // onDropdownShown: function(event) {
          //     Feature is disabled for now. Might be introduced in the future.
          //     const element = event.target.parentElement;
          //     element.scrollIntoView();
          // },

          buttonText: function buttonText(options) {
            if (options.length === 0) {
              return 'Velg tillegstekst';
            }
            return options.length + ' tillegstekst(er)';
          }
        });
      }, 250);
    },
    actions: {
      change: function change(event) {
        var _this = this;
        var selectedValue;
        if (this.get('multiple')) {
          var emptyValueObject;
          var selectedValueIds = Ember.$(event.target).val() || [];
          if (selectedValueIds.includes("n/a")) {
            selectedValueIds = selectedValueIds.removeObject("n/a");
            var key = this.get('optionValuePath');
            var label = this.get('optionLabelPath');
            emptyValueObject = new Ember.Object({});
            emptyValueObject[key] = "";
            emptyValueObject[label] = "n/a";
          }
          selectedValue = selectedValueIds.map(function (id) {
            return _this.get('content').findBy(_this.get('optionValuePath'), id);
          });
          if (emptyValueObject) {
            selectedValue.insertAt(0, emptyValueObject);
          }
        } else {
          selectedValue = Ember.$(event.target).val();
          selectedValue = this.get('content').findBy(this.get('optionValuePath'), selectedValue);
        }
        this.set('selectedValue', selectedValue);
        this.get('onChange')(selectedValue);
      }
    }
  });
});