define("frontend/pods/components/admin-completed-report-line/component", ["exports", "frontend/helpers/format-date"], function (_exports, _formatDate) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    download: Ember.inject.service('download'),
    actions: {
      download: function download(report, attachment) {
        var id = report.get('id');
        var name = report.get('location').get('name');
        var inspectionDate = (0, _formatDate.formatDate)([report.get('startInspectionDate')]);
        this.get('download').getReport(id, attachment || false).then(function (blob) {
          var urlObject = window.URL.createObjectURL(blob);
          if (attachment) {
            var a = document.createElement('a');
            a.href = urlObject;
            a.download = "".concat(name, " - Tilstandsrapport ").concat(inspectionDate, " (").concat(id, ").pdf");
            document.body.appendChild(a);
            a.click();
            a.remove();
          } else {
            window.open(urlObject, '_blank');
          }
        });
      }
    }
  });
});