define("frontend/pods/reports/inspector/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    networkstatus: Ember.inject.service('network-status')
  });
});