define("frontend/pods/user/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  Ember.Route.reopen({
    activate: function activate() {
      Ember.$('body').addClass(this.get('routeClassName'));
    },
    deactivate: function deactivate() {
      Ember.$('body').removeClass(this.get('routeClassName'));
    },
    routeClassName: function () {
      var routeClassName = this.routeName.replace(/\./g, '-').dasherize();
      //Instead of the application route name we use the app name
      if (routeClassName === 'application') {
        routeClassName = 'dweedo';
      }
      return routeClassName;
    }.property('routeName')
  });
  var _default = _exports.default = Ember.Route.extend({
    actions: {
      login: function login() {}
    }
  });
});