define("frontend/pods/feedback/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    items: {},
    show: function show(id, type, message, title) {
      this.get('items')[id].show(type, message, title);
    },
    hide: function hide(id) {
      this.get('items').getBy('id', id).hide();
    },
    add: function add(item) {
      this.get('items')[item.id] = item;
    },
    remove: function remove(item) {
      delete this.get('items')[item.id];
    }
  });
});