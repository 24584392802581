define("frontend/pods/user/password/forgot/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    errors: [],
    email_sent: false,
    ajax: Ember.inject.service('ajax'),
    reset: function reset() {
      this.set('email_sent', false);
      this.set('errors', []);
      this.set('email', '');
    },
    actions: {
      resetRequest: function resetRequest() {
        var _this = this;
        this.set('errors', []);
        var data = {
          'email': this.get('email')
        };
        var args = {
          method: 'POST',
          data: data
        };
        this.get('ajax').request('/password/reset/request', args).then(function () {
          _this.set('email_sent', true);
        }).catch(function (reason) {
          _this.set('email_sent', false);
          _this.set('errors', reason.errors);
        });
      }
    }
  });
});