define("frontend/pods/components/help-text/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "1vZ93GyH",
    "block": "{\"statements\":[[11,\"div\",[]],[16,\"class\",[34,[\"col-xs-12 help-text \",[33,[\"if\"],[[28,[\"important\"]],\"help-text-important\"],null],\" \"]]],[13],[0,\"\\n    \"],[11,\"p\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"toggle\"],null],null],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"expanded\"]],\"expanded\"],null]]]],[13],[1,[26,[\"text\"]],true],[14],[0,\"\\n    \"],[11,\"button\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"toggle\"],null],null],[16,\"class\",[34,[\"btn btn-xs btn-default \",[33,[\"if\"],[[28,[\"expanded\"]],\"active\"],null]]]],[13],[0,\"Vis hele\"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/help-text/template.hbs"
    }
  });
});