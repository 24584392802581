define("frontend/pods/image-resize/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    resizeImage: function resizeImage(file, width) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve) {
        _this.getOrientation(file).then(function (orientation) {
          var reader = new FileReader();
          reader.onloadend = function () {
            var img = new Image();
            img.onload = function () {
              return resolve(_this.resize(img, width, orientation));
            };
            img.src = reader.result;
          };
          reader.readAsDataURL(file);
        });
      });
    },
    resize: function resize(img, width, orientation) {
      if (width > img.width) width = img.width;
      var height = width * (img.height / img.width);
      var canvas = document.createElement('canvas');
      var canvasCtx = canvas.getContext("2d");
      canvas.width = width;
      canvas.height = height;
      this._rotate(canvas, canvasCtx, orientation);
      canvasCtx.drawImage(img, 0, 0, width, height);
      var data = canvas.toDataURL("image/jpeg", 0.8);
      img.remove();
      canvas.remove();
      return data;
    },
    _rotate: function _rotate(canvas, canvasCtx, orientation) {
      if (!orientation || orientation > 8) {
        return;
      }
      var width = canvas.width;
      var height = canvas.height;
      var styleWidth = canvas.style.width;
      var styleHeight = canvas.style.height;
      if (orientation > 4) {
        canvas.width = height;
        canvas.height = width;
        canvas.style.width = styleHeight;
        canvas.style.height = styleWidth;
      }
      if (orientation) {
        switch (orientation) {
          case 2:
            // horizontal flip
            canvasCtx.translate(width, 0);
            canvasCtx.scale(-1, 1);
            break;
          case 3:
            // 180° rotate left
            canvasCtx.translate(width, height);
            canvasCtx.rotate(180 * Math.PI / 180);
            break;
          case 4:
            // vertical flip
            canvasCtx.translate(0, height);
            canvasCtx.scale(1, -1);
            break;
          case 5:
            // vertical flip + 90 rotate right
            canvasCtx.rotate(0.5 * Math.PI);
            canvasCtx.scale(1, -1);
            break;
          case 6:
            // 90° rotate right
            canvasCtx.rotate(0.5 * Math.PI);
            canvasCtx.translate(0, -height);
            break;
          case 7:
            // horizontal flip + 90 rotate right
            canvasCtx.rotate(0.5 * Math.PI);
            canvasCtx.translate(width, -height);
            canvasCtx.scale(-1, 1);
            break;
          case 8:
            // 90° rotate left
            canvasCtx.rotate(-0.5 * Math.PI);
            canvasCtx.translate(-width, 0);
            break;
        }
      }
    },
    getOrientation: function getOrientation(file) {
      return new Ember.RSVP.Promise(function (resolve) {
        var reader = new FileReader();
        reader.onload = function (e) {
          var view = new DataView(e.target.result);
          if (view.getUint16(0, false) != 0xFFD8) return resolve(-2);
          var length = view.byteLength,
            offset = 2;
          while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;
            if (marker == 0xFFE1) {
              if (view.getUint32(offset += 2, false) != 0x45786966) return resolve(-1);
              var little = view.getUint16(offset += 6, false) == 0x4949;
              offset += view.getUint32(offset + 4, little);
              var tags = view.getUint16(offset, little);
              offset += 2;
              for (var i = 0; i < tags; i++) if (view.getUint16(offset + i * 12, little) == 0x0112) return resolve(view.getUint16(offset + i * 12 + 8, little));
            } else if ((marker & 0xFF00) != 0xFF00) break;else offset += view.getUint16(offset, false);
          }
          return resolve(-1);
        };
        reader.readAsArrayBuffer(file);
      });
    }
  });
});