define("frontend/pods/components/input-datetime/component", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    actions: {
      change: function change(value) {
        value = (0, _moment.default)(value).toDate();
        this.get('onChange')(value);
      }
    }
  });
});