define("frontend/pods/components/edit-report-deviation-line/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    expandedChanged: Ember.observer('rd._expanded', function () {
      if (this.get('rd._expanded')) {
        Ember.run.next(this, function () {
          Ember.$('.deviation .body.expand').ready(function () {
            var $node = Ember.$('.deviation .body.expand');
            Ember.$('html, body').animate({
              scrollTop: $node.offset().top - 60
            }, 400);
          });
        });
      }
    }),
    actions: {
      toggle: function toggle(obj, key) {
        obj.toggleProperty(key);
      }
    }
  });
});