define("frontend/pods/user/logout/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    session: Ember.inject.service('session'),
    activate: function activate() {
      this.get('session').invalidate();
      return this.transitionTo('/');
    }
  });
});