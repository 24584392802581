define("frontend/pods/user/password/forgot/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "dvuKoD7P",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"account-wall\"],[13],[0,\"\\n    \"],[11,\"form\",[]],[15,\"class\",\"password-reset-request\"],[5,[\"action\"],[[28,[null]],\"resetRequest\"],[[\"on\"],[\"submit\"]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"email_sent\"]]],null,{\"statements\":[[0,\"            \"],[11,\"h2\",[]],[13],[0,\"Sjekk e-posten din\"],[14],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"En e-post med instrukser har blitt sendt til \"],[1,[26,[\"email\"]],false],[0,\". Husk å sjekk spam katalogen da e-posten kan ha havnet der. Følg instruksene i denne e-posten for å resette passordet ditt.\"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"h2\",[]],[13],[0,\"Glemt passwordet ditt?\"],[14],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"Hvis du har glemt passordet ditt så fyll ut e-post adressen din under og du vil få tilsendt en e-post med instruksjoner om hvordan du kan endre passordet ditt.\"],[14],[0,\"\\n\\n            \"],[1,[33,[\"error-list\"],null,[[\"errors\"],[[28,[\"errors\"]]]]],false],[0,\"\\n            \"],[1,[33,[\"input\"],null,[[\"id\",\"value\",\"type\",\"placeholder\",\"class\",\"required\",\"autofocus\"],[\"email\",[28,[\"email\"]],\"email\",\"E-post\",\"form-control\",\"true\",\"true\"]]],false],[0,\"\\n            \"],[11,\"button\",[]],[15,\"class\",\"btn btn-lg btn-primary btn-block\"],[15,\"type\",\"submit\"],[13],[0,\"Send meg instrukser\"],[14],[0,\"\\n            \"],[6,[\"link-to\"],[\"user.login\"],[[\"class\"],[\"pull-right need-help\"]],{\"statements\":[[0,\"Logg inn\"]],\"locals\":[]},null],[11,\"span\",[]],[15,\"class\",\"clearfix\"],[13],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/user/password/forgot/template.hbs"
    }
  });
});