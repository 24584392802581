define("frontend/pods/inusemodels/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    store: Ember.inject.service('store'),
    data: [],
    fetch: false,
    isInUse: function isInUse(modelName, id) {
      var _this = this;
      return new Ember.RSVP.Promise(function (resolve) {
        if (!_this.get('fetch')) {
          _this.set('fetch', _this._fetchData());
        }
        _this.get('fetch').then(function () {
          resolve(_this._isInUse(modelName, id));
        });
      });
    },
    cleanup: function cleanup() {
      this.set('data', []);
      this.set('fetch', false);
    },
    _isInUse: function _isInUse(modelName, id) {
      var data = this.get('data').filterBy('type', modelName);
      data = data.filterBy('id', id);
      return data.length > 0;
    },
    _fetchData: function _fetchData() {
      var _this2 = this;
      var rv = [];
      var promises = [];
      var store = this.get('store');
      return new Ember.RSVP.Promise(function (resolve) {
        store.lfindAll('ireport').then(function (reports) {
          if (!reports.length) {
            _this2.set('data', rv);
            return resolve(rv);
          }
          reports.forEach(function (report) {
            var promise = report.getAllRelatedData(store, ['ireport', 'ilocation', 'ireport-entity', 'ireport-deviation', 'iimage', 'icontact-person', 'ientity-type', 'imanufacturer', 'ideviation-type']);
            promises.push(promise);
            rv.push(report);
          });
          Ember.RSVP.all(promises).then(function (result) {
            result.forEach(function (data) {
              rv = rv.concat(data);
            });
            _this2.set('data', rv);
            return resolve(rv);
          });
        });
      });
    }
  });
});