define("frontend/pods/components/image-view/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "6rThRSfT",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"img-container embed-responsive embed-responsive-4by3\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"image\"]]],null,{\"statements\":[[6,[\"if\"],[[28,[\"imagePopup\"]]],null,{\"statements\":[[0,\"            \"],[11,\"img\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"showImagePopup\"]]],null],true],null]],null],null],[15,\"class\",\"embed-responsive-item\"],[16,\"src\",[34,[[28,[\"image\",\"file\"]]]]],[13],[14],[0,\"\\n            \"],[1,[33,[\"image-popup\"],null,[[\"showDialog\",\"image\",\"title\"],[[28,[\"showImagePopup\"]],[28,[\"image\"]],[33,[\"concat\"],[[28,[\"label\"]],\"bilde\"],null]]]],false],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"img\",[]],[15,\"class\",\"embed-responsive-item\"],[16,\"src\",[34,[[28,[\"image\",\"file\"]]]]],[13],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"img\",[]],[15,\"class\",\"embed-responsive-item\"],[15,\"src\",\"/assets/images/placeholder.png\"],[13],[14],[0,\"\\n\"]],\"locals\":[]}],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/image-view/template.hbs"
    }
  });
});