define("frontend/pods/compatibility/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    isOk: function isOk() {
      if (!window.indexedDB) {
        return false;
      }

      // Safari < 10 has buggy indexeddb implementation
      var faultyIndexedDB = /^Apple/.test(navigator.vendor) && /AppleWebKit[/]60.*Version[/][89][.]/.test(navigator.appVersion);
      return !faultyIndexedDB;
    }
  });
});