define("frontend/pods/components/image-card-edit/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['col-xs-6', 'col-sm-3', 'col-md-3'],
    classNameBindings: ['newFile:new-file:existing-file'],
    newFile: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      if (this.get('size')) {
        var sizes = this.get('size').split(",");
        sizes[0] = "col-xs-" + sizes[0];
        sizes[1] = "col-sm-" + sizes[1];
        sizes[2] = "col-md-" + sizes[2];
        this.set('classNames', sizes);
      }
    }
  });
});