define("frontend/pods/user/password/reset/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "pYPTTJN2",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"account-wall\"],[13],[0,\"\\n    \"],[11,\"form\",[]],[15,\"class\",\"password-reset-request\"],[5,[\"action\"],[[28,[null]],\"changePassword\"],[[\"on\"],[\"submit\"]]],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"success\"]]],null,{\"statements\":[[0,\"            \"],[11,\"h2\",[]],[13],[0,\"Passordet ditt er nå oppdatert.\"],[14],[0,\"\\n            \"],[6,[\"link-to\"],[\"user.login\"],[[\"class\"],[\"btn btn-lg btn-block btn-primary\"]],{\"statements\":[[0,\"Logg inn\"]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"            \"],[11,\"h2\",[]],[13],[0,\"Bytt passord\"],[14],[0,\"\\n            \"],[11,\"p\",[]],[13],[0,\"Du kan nå velge ett nytt passord.\"],[14],[0,\"\\n\\n            \"],[1,[33,[\"error-list\"],null,[[\"errors\"],[[28,[\"errors\"]]]]],false],[0,\"\\n            \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"value\",\"placeholder\",\"class\",\"required\",\"autofocus\"],[\"password\",\"password\",[28,[\"new_password1\"]],\"Passord\",\"form-control\",\"true\",true]]],false],[0,\"\\n            \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"value\",\"placeholder\",\"class\",\"required\"],[\"confirm_password\",\"password\",[28,[\"new_password2\"]],\"Bekreft passord\",\"form-control\",\"true\"]]],false],[0,\"\\n            \"],[11,\"button\",[]],[15,\"class\",\"btn btn-lg btn-primary btn-block\"],[15,\"type\",\"submit\"],[13],[0,\"Bytt passord\"],[14],[0,\"\\n            \"],[6,[\"link-to\"],[\"user.login\"],[[\"class\"],[\"pull-right need-help\"]],{\"statements\":[[0,\"Gå til logg inn\"]],\"locals\":[]},null],[11,\"span\",[]],[15,\"class\",\"clearfix\"],[13],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/user/password/reset/template.hbs"
    }
  });
});