define("frontend/pods/components/image-picker-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    store: Ember.inject.service('store'),
    init: function init() {
      this._super.apply(this, arguments);
      this.set('colClasses', this.get('colClasses') || 'col-xs-12 col-sm-3 col-md-3');
      this.set('pickers', Ember.A());
      this.set('newPicker', this.createPicker(null));
      this.set('mainLabel', this.get('mainLabel'));
      this.set('label', this.get('label'));
      this.setLabels();
      this.resolveImages();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.resolveImages();
    },
    resolveImages: function resolveImages() {
      var _this = this;
      var images = this.get('images');
      images.then(function (images) {
        _this.set('pickers', []);
        images.forEach(function (image) {
          _this.pickers.pushObject(_this.createPicker(image));
        });
        _this.setLabels();
      });
    },
    createPicker: function createPicker(image) {
      return Ember.Object.create({
        label: '',
        image: image
      });
    },
    removeEmptyImages: function removeEmptyImages() {
      this.set('pickers', this.get('pickers').rejectBy('image', null));
    },
    setLabels: function setLabels() {
      var _this2 = this;
      var index = 0;
      this.pickers.forEach(function (picker) {
        if (index == 0) {
          picker.set('label', _this2.get('mainLabel'));
        } else {
          picker.set('label', _this2.get('label'));
        }
        index++;
      });
      if (this.get('pickers').length == 0) {
        this.get('newPicker').set('label', this.get('mainLabel'));
      } else {
        this.get('newPicker').set('label', this.get('label'));
      }
    },
    actions: {
      change: function change(picker, image) {
        this.get('onChange')(image, this.get('pickers').getEach('image').concat(image));
      },
      delete: function _delete(picker, image) {
        this.removeEmptyImages();
        this.setLabels();
        this.get('onDelete')(image, this.get('pickers').getEach('image'));
      }
    }
  });
});