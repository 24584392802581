define("frontend/pods/ifavorite-deviation/model", ["exports", "ember-data", "frontend/pods/favorite-deviation/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    deviationType: _emberData.default.belongsTo('ideviationType', {
      async: false
    })
  });
});