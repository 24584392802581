define("frontend/pods/reports/inspector/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "VDl5CrGF",
    "block": "{\"statements\":[[11,\"nav\",[]],[15,\"class\",\"navbar subnav-header-container\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"container\"],[13],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"online-status pull-right\"],[13],[0,\"\\n\"],[6,[\"if\"],[[28,[\"networkstatus\",\"online\"]]],null,{\"statements\":[[0,\"                \"],[11,\"span\",[]],[15,\"class\",\"text-success\"],[13],[0,\"Påkoblet\"],[14],[0,\"\\n\"]],\"locals\":[]},{\"statements\":[[0,\"                \"],[11,\"span\",[]],[15,\"class\",\"text-muted\"],[13],[0,\"Frakoblet\"],[14],[0,\"\\n\"]],\"locals\":[]}],[0,\"        \"],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"subnav-header\"],[13],[11,\"h1\",[]],[13],[0,\"Inspektør\"],[14],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"],[11,\"div\",[]],[15,\"class\",\"subnav-nav\"],[13],[0,\"\\n\"],[6,[\"bs-nav\"],null,[[\"type\"],[\"tabs\"]],{\"statements\":[[0,\"        \"],[6,[\"component\"],[[28,[\"nav\",\"item\"]]],null,{\"statements\":[[6,[\"component\"],[[28,[\"nav\",\"link-to\"]],\"reports.inspector.drafts\"],null,{\"statements\":[[0,\"Utkast\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n        \"],[6,[\"component\"],[[28,[\"nav\",\"item\"]]],null,{\"statements\":[[6,[\"component\"],[[28,[\"nav\",\"link-to\"]],\"reports.inspector.drivelist\"],null,{\"statements\":[[0,\"Kjøreliste\"]],\"locals\":[]},null]],\"locals\":[]},null],[0,\"\\n\"]],\"locals\":[\"nav\"]},null],[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"tab-content\"],[13],[0,\"\\n        \"],[1,[26,[\"outlet\"]],false],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/reports/inspector/template.hbs"
    }
  });
});