define("frontend/pods/components/circle-option/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "/GnGUoTB",
    "block": "{\"statements\":[[11,\"label\",[]],[13],[0,\"\\n    \"],[11,\"input\",[]],[16,\"checked\",[33,[\"is-equal\"],[[28,[\"current\"]],[28,[\"value\"]]],null],null],[15,\"type\",\"radio\"],[16,\"name\",[34,[[26,[\"name\"]],\"_grade\"]]],[16,\"value\",[34,[[26,[\"value\"]]]]],[16,\"onchange\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[28,[\"onChange\"]]],null]],[[\"value\"],[\"target.value\"]]],null],[13],[14],[0,\"\\n    \"],[11,\"span\",[]],[13],[1,[26,[\"label\"]],false],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/circle-option/template.hbs"
    }
  });
});