define("frontend/pods/components/image-popup/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "8QHhU9Bc",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"showDialog\"]]],null,{\"statements\":[[6,[\"bs-modal\"],null,[[\"class\",\"renderInPlace\",\"open\",\"fade\",\"onHide\"],[\"image-popup\",false,[28,[\"showDialog\"]],false,[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"showDialog\"]]],null],false],null]]],{\"statements\":[[6,[\"component\"],[[28,[\"modal\",\"header\"]]],null,{\"statements\":[[0,\"            \"],[11,\"h4\",[]],[15,\"class\",\"modal-title\"],[13],[1,[26,[\"title\"]],false],[14],[0,\"\\n\"]],\"locals\":[]},null],[6,[\"component\"],[[28,[\"modal\",\"body\"]]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[15,\"class\",\"thumbnail\"],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[33,[\"mut\"],[[28,[\"showDialog\"]]],null],false],null]],null],null],[13],[0,\"\\n                \"],[11,\"div\",[]],[15,\"class\",\"img-container embed-responsive embed-responsive-4by3\"],[13],[0,\"\\n                    \"],[11,\"img\",[]],[15,\"class\",\"embed-responsive-item\"],[16,\"src\",[34,[[28,[\"image\",\"file\"]]]]],[13],[14],[0,\"\\n                \"],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"modal\"]},null]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/image-popup/template.hbs"
    }
  });
});