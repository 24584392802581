define("frontend/initializers/ajax-setup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize( /* application */
  ) {
    Ember.$.ajaxSetup({
      xhrFields: {
        withCredentials: true
      }
    });
  }
  var _default = _exports.default = {
    name: 'ajax-setup',
    initialize: initialize
  };
});