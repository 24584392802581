define("frontend/pods/components/entity-card/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "zg2YPclh",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"col-sm-3 col-md-3\"],[13],[0,\"\\n    \"],[11,\"div\",[]],[15,\"class\",\"thumbnail\"],[13],[0,\"\\n        \"],[11,\"img\",[]],[15,\"width\",\"100%\"],[15,\"src\",\"/assets/images/temp/sandkasse.jpg\"],[15,\"alt\",\"\"],[13],[14],[0,\"\\n        \"],[11,\"div\",[]],[15,\"class\",\"caption\"],[13],[0,\"\\n            \"],[11,\"h5\",[]],[13],[0,\"Sandkasse\"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/entity-card/template.hbs"
    }
  });
});