define("frontend/pods/iuser/model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    groups: _emberData.default.hasMany('igroup'),
    type: Ember.computed('groups', function () {
      var _this = this;
      var promise = new Ember.RSVP.Promise(function (resolve) {
        _this.get('groups').then(function (groups) {
          var id = groups.get('firstObject').get('id');
          var name = groups.get('firstObject').get('name');
          var identifier = 'customer';
          if (id == 1) {
            identifier = 'admin';
          } else if (id == 2) {
            identifier = 'admin';
          } else if (id == 3) {
            identifier = 'customer';
          } else if (id == 4) {
            identifier = 'inspector';
          }
          return resolve({
            name: name,
            identifier: identifier
          });
        });
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    })
  });
});