define("frontend/pods/application/adapter", ["exports", "ember-indexeddb-adapter/adapters/indexeddb"], function (_exports, _indexeddb) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _indexeddb.default.extend({
    dbName: 'lekeplass',
    version: 24,
    models: ['iuser', 'igroup', 'ientity-type', 'ideviation-type', 'iimage', 'ireport', 'ilocation', 'ideviation-type-text', 'imanufacturer', 'ireport-entity', 'ireport-deviation', 'ifavorite-deviation', 'icontact-person', 'iinspector'],
    init: function init() {
      var self = this;
      var dbName = this.get('dbName');
      var dbVersion = this.get('version');
      var request = window.indexedDB.open(dbName, dbVersion);
      request.onerror = function (error) {
        console.error("Unable to open IndexedDB: dbName='".concat(dbName, "', version='").concat(dbVersion, "'"));
        console.error(error);
        Sentry.addBreadcrumb({
          category: 'IndexedDBAdapter',
          message: 'Unable to open IndexedDB',
          level: Sentry.Severity.Error
        });
      };
      request.onupgradeneeded = function (event) {
        var db = event.target.result;
        db.onerror = function (event) {
          var message = "IndexedDB error: ".concat(event.target.errorCode);
          Sentry.addBreadcrumb({
            category: 'IndexedDBAdapter',
            message: message,
            level: Sentry.Severity.Error
          });
          console.error(message);
        };
        self.get('models').forEach(function (model) {
          if (!db.objectStoreNames.contains(model)) {
            db.createObjectStore(model, {
              keyPath: 'id'
            });
          }
        });
        if (event.oldVersion <= 21) {
          self.get('models').forEach(function (modelName) {
            var objectStore = event.currentTarget.transaction.objectStore(modelName);
            objectStore.createIndex("id", "id");
          });
        }
        if (event.oldVersion <= 22) {
          self.get('models').forEach(function (modelName) {
            var objectStore = event.currentTarget.transaction.objectStore(modelName);
            objectStore.createIndex("modified", "modified");
          });
        }
      };
    },
    deleteAllTables: function deleteAllTables() {
      var _this = this;
      this.openDatabase().then(function (db) {
        _this.get('models').forEach(function (model) {
          if (db.objectStoreNames.contains(model)) {
            var objectStore = db.transaction([model], 'readwrite').objectStore(model);
            objectStore.clear();
          }
        });
      });
    },
    /**
     * Find all records
     * param {object} store DS.Store
     * param {object} type DS.Model
     * return {promise} Promise that contains the record
     */
    findAllKeys: function findAllKeys(store, type) {
      var _this2 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2.openDatabase().then(function (db) {
          var data = [];
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName]).objectStore(modelName);
          var index = objectStore.index('id');
          var request = index.openKeyCursor();
          request.onsuccess = function (event) {
            var cursor = event.target.result;
            if (cursor) {
              data.push(cursor.key);
              cursor.continue();
            } else {
              db.close();
              Ember.run(null, resolve, data);
            }
          };
          request.onerror = function (event) {
            var message = "IndexedDB error: ".concat(event.target.errorCode);
            Sentry.addBreadcrumb({
              category: 'IndexedDBAdapter',
              message: message,
              level: Sentry.Severity.Error
            });
            console.error(message);
            db.close();
            Ember.run(null, reject, event);
          };
        });
      });
    },
    deleteRecordById: function deleteRecordById(store, type, id) {
      var _this3 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this3.openDatabase().then(function (db) {
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName], 'readwrite').objectStore(modelName);
          var request = objectStore.delete(id);
          request.onsuccess = function () {
            db.close();
            Ember.run(null, resolve);
          };
          request.onerror = function (event) {
            var message = "IndexedDB error: ".concat(event.target.errorCode);
            Sentry.addBreadcrumb({
              category: 'IndexedDBAdapter',
              message: message,
              level: Sentry.Severity.Error
            });
            console.error(message);
            db.close();
            Ember.run(null, reject, event);
          };
        });
      });
    },
    findMaxValue: function findMaxValue(store, type, field, direction) {
      var _this4 = this;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this4.openDatabase().then(function (db) {
          direction = direction || 'next';
          var modelName = type.modelName;
          var objectStore = db.transaction([modelName]).objectStore(modelName);
          var index = objectStore.index(field);
          var request = index.openCursor(null, direction);
          request.onsuccess = function (event) {
            var cursor = event.target.result;
            if (cursor) {
              Ember.run(null, resolve, cursor.value[field]);
            } else {
              db.close();
              Ember.run(null, resolve, null);
            }
          };
          request.onerror = function (event) {
            var message = "IndexedDB error: ".concat(event.target.errorCode);
            Sentry.addBreadcrumb({
              category: 'IndexedDBAdapter',
              message: message,
              level: Sentry.Severity.Error
            });
            console.error(message);
            db.close();
            Ember.run(null, reject, event);
          };
        });
      });
    }
  });
});