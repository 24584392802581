define("frontend/pods/components/drive-list-table/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    selected: function selected() {
      return this.get('locations').filterBy('_selected', true);
    },
    actions: {
      copy: function copy() {
        this.get('onCopy')(this.selected());
      }
    }
  });
});