define("frontend/pods/components/help-text/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['row nested-row'],
    expanded: false,
    actions: {
      toggle: function toggle() {
        this.toggleProperty('expanded');
      }
    }
  });
});