define("frontend/pods/user/password/reset/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    errors: [],
    ajax: Ember.inject.service('ajax'),
    actions: {
      changePassword: function changePassword() {
        var _this = this;
        this.set('errors', []);
        var args = {
          method: 'POST',
          data: {
            'user_id': this.get('params').reset_user_id,
            'token': this.get('params').reset_token,
            'new_password1': this.get('new_password1'),
            'new_password2': this.get('new_password2')
          }
        };
        this.get('ajax').request('/password/reset', args).then(function () {
          _this.set('success', true);
        }).catch(function (reason) {
          _this.set('success', false);
          _this.set('errors', reason.errors);
        });
      }
    }
  });
});