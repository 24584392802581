define("frontend/pods/components/confirm-button/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    dialogSubmitType: 'primary',
    // can be set do 'sucess', 'danger', etc.
    showConfirm: false,
    actions: {
      submit: function submit() {
        this.toggleProperty('showConfirm');
        return this.get('onClick')();
      }
    }
  });
});