define("frontend/pods/reports/admin/pending/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    spinner: Ember.inject.service('spinner'),
    queryParams: {
      page: {
        refreshModel: true
      },
      inspector: {
        refreshModel: true
      },
      customer: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var reportQuery = Ember.$.extend({
        "is_admin": 1,
        "admin_pending": 1,
        include: 'location,images,location.customer,inspector,contact',
        "page_size": 50
      }, params);
      var customerQuery = {
        "is_admin": 1,
        "admin_pending": 1
      };
      return Ember.RSVP.hash({
        params: params,
        reports: this.store.query('report', reportQuery),
        customers: this.store.query('customer', customerQuery),
        inspectors: this.store.query('inspector', {
          "is_admin": 1,
          "admin_pending": 1
        })
      });
    },
    afterModel: function afterModel(data) {
      data.sort = [new Ember.Object({
        "id": "alpha",
        "name": "Alfabetisk (A-Å)"
      }), new Ember.Object({
        "id": "inspection_date",
        "name": "Inspeksjonsdato (eldst først)"
      }), new Ember.Object({
        "id": "inspection_date_desc",
        "name": "Inspeksjonsdato (nyest først)"
      }), new Ember.Object({
        "id": "inspector",
        "name": "Inspektør (etternavn A-Å)"
      })];
    },
    actions: {
      willTransition: function willTransition(transition) {
        if (transition.targetName === 'reports.admin.completed') {
          this.get('spinner').show('main');
        }
      }
    }
  });
});