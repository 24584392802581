define("frontend/pods/user/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    compatibility: Ember.inject.service('compatibility'),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      if (!this.get('compatibility').isOk()) {
        return this.transitionTo('not-supported');
      }
    }
  });
});