define("frontend/pods/network-status/service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Service.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('online', navigator.onLine);
      window.addEventListener('online', function () {
        _this.set('online', true);
      });
      window.addEventListener('offline', function () {
        _this.set('online', false);
      });
    }
  });
});