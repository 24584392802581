define("frontend/initializers/simple-auth-token", ["exports", "ember-simple-auth-token/authenticators/token", "ember-simple-auth-token/authenticators/jwt", "ember-simple-auth-token/authorizers/token", "ember-simple-auth-token/configuration", "frontend/config/environment"], function (_exports, _token, _jwt, _token2, _configuration, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
    Ember Simple Auth Token's Initializer.
    By default load both the Token and JWT (with refresh) Authenticators.
  */
  var _default = _exports.default = {
    name: 'ember-simple-auth-token',
    before: 'ember-simple-auth',
    initialize: function initialize(container) {
      _configuration.default.load(container, _environment.default['ember-simple-auth-token'] || {});
      container.register('authorizer:token', _token2.default);
      container.register('authenticator:token', _token.default);
      container.register('authenticator:jwt', _jwt.default);
    }
  };
});