define("frontend/pods/components/image-picker-list/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "cc8sCr1S",
    "block": "{\"statements\":[[6,[\"if\"],[[28,[\"show\"]]],null,{\"statements\":[[0,\"    \"],[11,\"div\",[]],[15,\"class\",\"row\"],[13],[0,\"\\n\"],[6,[\"each\"],[[28,[\"pickers\"]]],null,{\"statements\":[[0,\"            \"],[11,\"div\",[]],[16,\"class\",[34,[[26,[\"colClasses\"]]]]],[13],[0,\"\\n                \"],[1,[33,[\"image-picker\"],null,[[\"label\",\"image\",\"onChange\",\"onDelete\"],[[28,[\"picker\",\"label\"]],[28,[\"picker\",\"image\"]],[33,[\"action\"],[[28,[null]],\"change\",[28,[\"picker\"]]],null],[33,[\"action\"],[[28,[null]],\"delete\",[28,[\"picker\"]]],null]]]],false],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[\"picker\"]},null],[0,\"        \"],[11,\"div\",[]],[16,\"class\",[34,[[26,[\"colClasses\"]]]]],[13],[0,\"\\n            \"],[1,[33,[\"image-picker\"],null,[[\"label\",\"image\",\"onChange\",\"onDelete\"],[[28,[\"newPicker\",\"label\"]],[28,[\"newPicker\",\"image\"]],[33,[\"action\"],[[28,[null]],\"change\",[28,[\"newPicker\"]]],null],[33,[\"action\"],[[28,[null]],\"delete\",[28,[\"picker\"]]],null]]]],false],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/image-picker-list/template.hbs"
    }
  });
});