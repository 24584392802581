define("frontend/pods/components/calculator-sharedswing/component", ["exports", "frontend/pods/components/calculator-oneseatswing/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.get('outputs').findBy('code', '2420').set('code', '2420Fhu');
      this.get('inputs').findBy('id', 'seattype').set('options', [{
        value: 4,
        label: "Gruppe",
        selected: "selected"
      }]);
      this.get('inputs').findBy('id', 'seattype').set('value', 4);

      // Any changes here also requires changes in models.py
      var output = this.get('outputs').findBy('code', '2442');
      output.set('code', '2442KoHu');
      output.set('constant', 0.3);
    },
    calculate: function calculate() {
      this.setResultValue('2441StativKlaring', null);
      this.setResultValue('fallheight', null);
      this.setResultValue('fallheight2', null);
      this.setResultValue('2442', null);
      this.setResultValue('14285Dybde', null);
      if (this.canCalc(['length'])) {
        var length = this.getInputValue('length');
        this.setResultValue('fallheight', length * 0.867);
        this.setResultValue('2441StativKlaring', length * 0.2 + 40);
        this.setResultValue('14285Dybde', length * 0.867 <= 200 ? 30 : 40);
        if (this.canCalc(['edge'])) {
          var edge = this.getInputValue('edge');
          var edge_value = edge == 2 ? 175 : 225;
          this.setResultValue('fallheight2', length * 0.867 + edge_value);
          this.setResultValue('14284HiSHu', length * 0.867 + edge_value);
        }
        if (this.canCalc(['seatwidth'])) {
          var seatwidth = this.getInputValue('seatwidth');
          var constant = this.getOutputField('2442', 'constant');
          this.setResultValue('2442', length * constant + seatwidth);
        }
      }
      if (this.canCalc(['seattype'])) {
        var seattype = this.getInputValue('seattype');
        var value = '';
        if (seattype == 1) {
          value = 35;
        } else if (seattype == 2 || seattype == 4) {
          value = 40;
        } else if (seattype == 3) {
          value = 10;
        }
        this.setResultValue('2420', value);
      }
    }
  });
});