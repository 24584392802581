define("frontend/pods/main/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    user: Ember.inject.service('user'),
    sync: Ember.inject.service('sync'),
    actions: {
      sync: function sync() {
        document.location.reload();
      },
      syncAndDelete: function syncAndDelete() {
        var adapter = this.get('store').adapterFor('iuser');
        adapter.deleteAllTables();
        Ember.run.later(this, function () {
          document.location.href = "/";
        }, 1000);
      }
    }
  });
});