define("frontend/pods/inspector/model", ["exports", "ember-data", "frontend/pods/utils/models"], function (_exports, _emberData, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_models.default, {
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    modified: _emberData.default.attr('number'),
    name: Ember.computed('firstname', 'lastname', function () {
      return "".concat(this.get('firstname'), " ").concat(this.get('lastname'));
    })
  });
});