define("frontend/router", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('main', {
      path: '/'
    }, function () {
      this.route('reports', {
        path: '/',
        resetNamespace: true
      }, function () {
        this.route('admin', function () {
          this.route('pending');
          this.route('completed');
        });
        this.route('customer', function () {
          this.route('completed');
          this.route('contactinfo');
        });
        this.route('inspector', function () {
          this.route('drafts');
          this.route('drivelist');
          this.route('pending');
          this.route('completed');
        });
        this.route('view');
        this.route('edit', {
          path: '/edit/:ireport_id/:model_name'
        });
      });
      this.route('loading');
    });
    this.route('user', function () {
      this.route('login');
      this.route('logout');
      this.route('password', function () {
        this.route('forgot');
        this.route('reset', {
          path: '/reset/:reset_user_id/:reset_token'
        });
      });
      this.route('register');
    });
    this.route('not-supported');
  });
  var _default = _exports.default = Router;
});