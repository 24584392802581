define("frontend/pods/user/password/forgot/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    resetController: function resetController(controller) {
      controller.reset();
    }
  });
});