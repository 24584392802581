define("frontend/pods/components/feedback-message/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    feedback: Ember.inject.service('feedback'),
    classNames: ['alert', 'alert-dismissible'],
    classNameBindings: ['doShow:show:hide', 'typeClass'],
    doShow: false,
    type: 'success',
    timeout: 3000,
    // 5 seconds

    typeClass: Ember.computed('type', function () {
      return 'alert-' + this.get('type');
    }),
    didInsertElement: function didInsertElement() {
      this.get('feedback').add(this);
      this.set('doShow', false);
    },
    willDestroyElement: function willDestroyElement() {
      this.set('doShow', false);
      this.get('feedback').remove(this);
      if (this.get('later')) {
        Ember.run.cancel(this.get('later'));
      }
    },
    show: function show(type, message, title) {
      var _this = this;
      this.set('message', type);
      this.set('message', message);
      this.set('title', title);
      this.set('type', type);
      this.set('doShow', true);
      if (type != 'danger') {
        var later = Ember.run.later(this, function () {
          _this.hide();
        }, this.get('timeout'));
        this.set('later', later);
      }
    },
    hide: function hide() {
      this.set('doShow', false);
    },
    actions: {
      hide: function hide() {
        return this.hide();
      }
    }
  });
});