define("frontend/pods/components/check-box/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "czGDlk8g",
    "block": "{\"statements\":[[11,\"label\",[]],[16,\"class\",[34,[\"chk \",[33,[\"if\"],[[28,[\"small\"]],\"chk-sm\"],null],\" \",[33,[\"if\"],[[28,[\"disabled\"]],\"disabled\"],null]]]],[13],[0,\"\\n    \"],[1,[33,[\"input\"],null,[[\"disabled\",\"type\",\"checked\",\"name\"],[[28,[\"disabled\"]],\"checkbox\",[28,[\"checked\"]],\"selected[]\"]]],false],[0,\"\\n    \"],[11,\"span\",[]],[13],[14],[0,\"\\n    \"],[1,[26,[\"label\"]],false],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/check-box/template.hbs"
    }
  });
});