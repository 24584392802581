define("frontend/pods/reports/inspector/drivelist/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function model() {
      return this.store.query('location', {
        "drive_list": 1,
        include: 'reports,county,customer',
        "page_size": 30,
        "q": ''
      });
    }
  });
});