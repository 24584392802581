define("frontend/pods/ilocation/model", ["exports", "ember-data", "frontend/pods/location/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    contactpeople: _emberData.default.hasMany('icontact-person')
  });
});