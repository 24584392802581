define("frontend/pods/user/register/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "FWv8hMCh",
    "block": "{\"statements\":[[11,\"div\",[]],[15,\"class\",\"account-wall\"],[13],[0,\"\\n    \"],[11,\"form\",[]],[15,\"class\",\"register\"],[13],[0,\"\\n        \"],[11,\"p\",[]],[13],[0,\"Benytt e-post adresse som du fikk meldingen til. Denne er registrert i systemet.\"],[14],[0,\"\\n        \"],[11,\"p\",[]],[13],[0,\"Velg ønsket passord for å opprette tilgang.\"],[14],[0,\"\\n\\n        \"],[1,[33,[\"error-list\"],null,[[\"errors\"],[[28,[\"errors\"]]]]],false],[0,\"\\n      \"],[1,[33,[\"input\"],null,[[\"id\",\"value\",\"type\",\"placeholder\",\"class\",\"required\",\"autofocus\"],[\"email\",[28,[\"email\"]],\"email\",\"E-post\",\"form-control\",\"true\",\"true\"]]],false],[0,\"\\n      \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"value\",\"placeholder\",\"class\",\"required\",\"autofocus\"],[\"password\",\"password\",[28,[\"new_password1\"]],\"Ønsket passord\",\"form-control\",\"true\",true]]],false],[0,\"\\n      \"],[1,[33,[\"input\"],null,[[\"id\",\"type\",\"value\",\"placeholder\",\"class\",\"required\"],[\"confirm_password\",\"password\",[28,[\"new_password2\"]],\"Bekreft passord\",\"form-control\",\"true\"]]],false],[0,\"\\n        \"],[1,[33,[\"bs-button\"],null,[[\"defaultText\",\"pendingText\",\"onClick\",\"type\",\"class\",\"size\"],[\"Registrer\",\"Vent...\",[33,[\"action\"],[[28,[null]],\"register\"],null],\"primary\",\"btn-block\",\"lg\"]]],false],[0,\"\\n        \"],[6,[\"link-to\"],[\"user.login\"],[[\"class\"],[\"pull-left need-help\"]],{\"statements\":[[0,\"Logg inn\"]],\"locals\":[]},null],[0,\"\\n        \"],[6,[\"link-to\"],[\"user.password.forgot\"],[[\"class\"],[\"pull-right need-help\"]],{\"statements\":[[0,\"Glemt passordet?\"]],\"locals\":[]},null],[11,\"span\",[]],[15,\"class\",\"clearfix\"],[13],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/user/register/template.hbs"
    }
  });
});