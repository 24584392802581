define("frontend/pods/ideviation-type/model", ["exports", "ember-data", "frontend/pods/deviation-type/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    texts: _emberData.default.hasMany('ideviationTypeText'),
    images: _emberData.default.hasMany('iimage', {
      async: true
    })
  });
});