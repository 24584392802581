define("frontend/pods/ireport/model", ["exports", "ember-data", "frontend/pods/report/model"], function (_exports, _emberData, _model) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _model.default.extend({
    _store: Ember.inject.service('store'),
    reportEntities: _emberData.default.hasMany('ireport-entity', {
      loadOnSave: true,
      recursiveInUse: true,
      cascadeDelete: true
    }),
    images: _emberData.default.hasMany('iimage', {
      async: true,
      loadOnSave: true,
      recursiveInUse: true,
      cascadeDelete: true
    }),
    location: _emberData.default.belongsTo('ilocation', {
      recursiveInUse: true,
      cascadeDelete: true
    }),
    contact: _emberData.default.belongsTo('icontact-person', {
      loadOnSave: true,
      recursiveInUse: true,
      cascadeDelete: true
    }),
    inspector: _emberData.default.belongsTo('iinspector')
  });
});