define("frontend/pods/user/model", ["exports", "ember-data", "frontend/pods/utils/models"], function (_exports, _emberData, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_models.default, {
    email: _emberData.default.attr(),
    groups: _emberData.default.hasMany('group'),
    contactperson: _emberData.default.belongsTo('contact-person')
  });
});