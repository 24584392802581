define("frontend/pods/reports/inspector/drafts/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    model: function model() {
      var _this = this;
      var query = function query() {
        return _this.store.findAll('ireport');
      };
      this.set('query', query);
      return query();
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('query', this.get('query'));
    }
  });
});