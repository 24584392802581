define("frontend/pods/utils/search-dialog", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Mixin.create({
    store: Ember.inject.service('store'),
    modelName: '',
    searchText: '',
    items: [],
    searchFields: ['name'],
    equalHeightSelector: '',
    classNameBindings: ['show:show:hide'],
    show: false,
    loading: false,
    viewType: 'list',
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pages', 0);
      this.set('currentPage', 1);
      this.set('pageSize', 30);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var selector = this.get('equalHeightSelector');
      if (selector) {
        this.$(selector).equalHeights();
      }
    },
    loadData: function loadData() {
      var _this = this;
      this.set('loading', true);
      this.getData().then(function (items) {
        _this.set('items', _this.paginate(_this.sort(items)));
        _this.set('loading', false);
      });
    },
    getData: function getData() {
      if (!this.get('_data')) {
        this.set('_data', this.get('store').findAll(this.get('modelName'), {
          backgroundReload: false,
          reload: true
        }));
      }
      return this.get('_data');
    },
    sort: function sort(data) {
      return data.sortBy('name');
    },
    search: function search(data, text) {
      var _this2 = this;
      var searchText = text.toLowerCase();
      return this.sort(data.filter(function (item) {
        return _this2.get('searchFields').any(function (field) {
          return (item.get(field) || '').toLowerCase().includes(searchText);
        });
      }));
    },
    paginate: function paginate(data) {
      var pageSize = this.get('pageSize');
      if (pageSize > 1) {
        var currentPage = this.get('currentPage');
        var pageCount = Math.ceil(data.length / pageSize);
        var start = currentPage * pageSize - pageSize;
        var end = Math.min(currentPage * pageSize, data.length);
        var pages = Array.from(Array(pageCount + 1).keys());
        pages = pages.removeAt(0);
        this.set('pages', pages);
        this.set('shouldPaginate', pageCount > 1);
        this.set('hasNextPage', currentPage < pageCount);
        this.set('hasPrevPage', currentPage > 1);
        return data.slice(start, end);
      } else {
        return data;
      }
    },
    _observeOpen: Ember.observer('show', function () {
      if (this.get('show')) {
        this.loadData();
      } else {
        this.set('items', []);
        this.set('_data', false);
      }
    }),
    doSearch: function doSearch() {
      var _this3 = this;
      var text = this.get('searchText');
      this.getData().then(function (data) {
        _this3.set('currentPage', 1);
        _this3.set('items', _this3.paginate(_this3.search(data, text)));
      });
    },
    actions: {
      onShow: function onShow() {
        this.loadData();
      },
      setViewType: function setViewType(type) {
        this.set('viewType', type);
      },
      search: function search(text) {
        if (text.length < 2) {
          this.loadData();
          return;
        }
        Ember.run.debounce(this, this.doSearch, 500, false);
      },
      empty: function empty() {
        this.set('searchText', '');
        this.loadData();
        this.$('input[type=text]').focus();
      },
      pick: function pick(item) {
        this.set('show', false);
        this.get('onPick')(item);
      },
      pageChanged: function pageChanged(page) {
        this.set('currentPage', page);
        if (this.get('searchText')) {
          this.send('search', this.get('searchText'));
        } else {
          this.loadData();
        }
      },
      prevPage: function prevPage() {
        if (this.get('hasPrevPage')) {
          this.decrementProperty('currentPage');
          if (this.get('searchText')) {
            this.send('search', this.get('searchText'));
          } else {
            this.loadData();
          }
        }
      },
      nextPage: function nextPage() {
        if (this.get('hasNextPage')) {
          this.incrementProperty('currentPage');
          if (this.get('searchText')) {
            this.send('search', this.get('searchText'));
          } else {
            this.loadData();
          }
        }
      }
    }
  });
});