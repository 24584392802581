define("frontend/pods/reports/admin/completed/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service('ajax'),
    feedback: Ember.inject.service('feedback'),
    sync: Ember.inject.service('sync'),
    spinner: Ember.inject.service('spinner'),
    selectedReports: Ember.computed('model.reports.@each._selected', function () {
      return this.get('model.reports').filterBy('_selected');
    }),
    queryParams: ['page', 'inspector', 'customer', 'sort', 'year', 'notified'],
    page: 1,
    inspector: null,
    customer: null,
    sort: 'inspection_date_desc',
    year: String(new Date().getFullYear()),
    notified: 'yes',
    actions: {
      undoCompletion: function undoCompletion(reports) {
        var _this = this;
        this.get('spinner').show('main');
        return this.get('ajax').jsonRequest('/reports/undocompletion', reports.getEach('id')).then(function () {
          var message = reports.length + " rapport(er) fikk ferdigstilling opphevet.";
          _this.get('feedback').show('crud-list', 'success', message, "Oppheving av ferdigstilling utført");
          reports.setEach('_selected', false);
          _this.get('model').reports.update().then(function () {
            _this.get('spinner').hide('main');
          });
        });
      },
      notifyCustomers: function notifyCustomers(reports) {
        var _this2 = this;
        this.get('spinner').show('main');
        if (reports.filterBy('has_contact_emails', false).length > 0) {
          this.get('spinner').hide('main');
          alert("En eller flere av de valgte rapportene mangler kontaktperson!");
          return;
        }
        return this.get('ajax').jsonRequest('/reports/notify', reports.getEach('id')).then(function () {
          var message = reports.length + " kontaktperson(er) fikk tilsendt e-post.";
          _this2.get('feedback').show('crud-list', 'success', message, "Utsending av e-post fullført");
          reports.setEach('_selected', false);
          _this2.get('model').reports.update().then(function () {
            _this2.get('spinner').hide('main');
          });
        });
      },
      showPreview: function showPreview() {
        this.toggleProperty('showPreviewDialog');
      },
      closePreview: function closePreview() {
        this.toggleProperty('showPreviewDialog');
      },
      setFilter: function setFilter(field, obj) {
        var value = null;
        if (obj) {
          value = obj.get('id');
        }
        this.set(field, value);
      }
    }
  });
});