define("frontend/pods/components/pagination-toolbar/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "fcW/fWXQ",
    "block": "{\"statements\":[[11,\"nav\",[]],[15,\"class\",\"text-center\"],[15,\"aria-label\",\"Page navigation\"],[13],[0,\"\\n    \"],[11,\"ul\",[]],[15,\"class\",\"pagination\"],[13],[0,\"\\n        \"],[11,\"li\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"hasPrevPage\"]],\"\",\"disabled\"],null]]]],[15,\"style\",\"cursor:pointer;\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[28,[\"onPrevPageChange\"]]],null]],null],null],[15,\"aria-label\",\"Previous\"],[13],[0,\"\\n                «\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n\"],[6,[\"each\"],[[28,[\"pages\"]]],null,{\"statements\":[[0,\"            \"],[11,\"li\",[]],[16,\"class\",[34,[[33,[\"if\"],[[33,[\"is-equal\"],[[28,[\"page\"]],[28,[\"currentPage\"]]],null],\"active\",\"\"],null]]]],[15,\"style\",\"cursor:pointer;\"],[13],[0,\"\\n                \"],[11,\"span\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[28,[\"onPageChange\"]],[28,[\"page\"]]],null]],null],null],[13],[1,[28,[\"page\"]],false],[14],[0,\"\\n            \"],[14],[0,\"\\n\"]],\"locals\":[\"page\"]},null],[0,\"        \"],[11,\"li\",[]],[16,\"class\",[34,[[33,[\"if\"],[[28,[\"hasNextPage\"]],\"\",\"disabled\"],null]]]],[15,\"style\",\"cursor:pointer;\"],[13],[0,\"\\n            \"],[11,\"span\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],[33,[\"action\"],[[28,[null]],[28,[\"onNextPageChange\"]]],null]],null],null],[15,\"aria-label\",\"Next\"],[13],[0,\"\\n                »\\n            \"],[14],[0,\"\\n        \"],[14],[0,\"\\n    \"],[14],[0,\"\\n\"],[14],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/pagination-toolbar/template.hbs"
    }
  });
});