define("frontend/pods/components/feedback-message/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X8N84uqr",
    "block": "{\"statements\":[[11,\"button\",[]],[16,\"onclick\",[33,[\"action\"],[[28,[null]],\"hide\"],null],null],[15,\"type\",\"button\"],[15,\"class\",\"close\"],[15,\"aria-label\",\"Close\"],[13],[11,\"span\",[]],[15,\"aria-hidden\",\"true\"],[13],[0,\"×\"],[14],[14],[0,\"\\n\"],[6,[\"if\"],[[28,[\"title\"]]],null,{\"statements\":[[11,\"strong\",[]],[13],[1,[26,[\"title\"]],false],[14]],\"locals\":[]},null],[0,\" \"],[1,[26,[\"message\"]],false],[0,\"\\n\"],[1,[33,[\"error-list\"],null,[[\"errors\"],[[28,[\"errors\"]]]]],false],[0,\"\\n\"]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/feedback-message/template.hbs"
    }
  });
});