define("frontend/pods/user/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Controller.extend({
    session: Ember.inject.service('session'),
    errors: [],
    actions: {
      login: function login() {
        var _this = this;
        this.set('errors', []);
        var credentials = {
          'identification': this.get('email'),
          'password': this.get('password')
        };
        this.get('session').authenticate('authenticator:token', credentials).then(function () {
          Sentry.configureScope(function (scope) {
            scope.setUser({
              "email": _this.get('email')
            });
          });
          _this.set('email', null);
          _this.set('password', null);
          return _this.transitionToRoute('main');
        }).catch(function (reason) {
          _this.set('errors', reason.errors || reason.non_field_errors);
        });
      }
    }
  });
});