define("frontend/pods/reports/admin/completed/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Route.extend({
    spinner: Ember.inject.service('spinner'),
    queryParams: {
      page: {
        refreshModel: true
      },
      inspector: {
        refreshModel: true
      },
      customer: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      year: {
        refreshModel: true
      },
      notified: {
        refreshModel: true
      }
    },
    model: function model(params) {
      var reportQuery = Ember.$.extend({
        "is_admin": 1,
        "admin_completed": 1,
        include: 'location,images,location.customer,inspector,contact',
        "page_size": 50
      }, params);
      var customerQuery = {
        "is_admin": 1,
        "admin_completed": 1
      };
      return Ember.RSVP.hash({
        params: params,
        reports: this.store.query('report', reportQuery),
        customers: this.store.query('customer', customerQuery),
        inspectors: this.store.query('inspector', {
          "is_admin": 1,
          "admin_completed": 1
        })
      });
    },
    afterModel: function afterModel(data) {
      data.sort = [new Ember.Object({
        "id": "alpha",
        "name": "Alfabetisk (A-Å)"
      }), new Ember.Object({
        "id": "inspection_date",
        "name": "Inspeksjonsdato (eldst først)"
      }), new Ember.Object({
        "id": "inspection_date_desc",
        "name": "Inspeksjonsdato (nyest først)"
      }), new Ember.Object({
        "id": "inspector",
        "name": "Inspektør (etternavn A-Å)"
      })];
      data.notify = [new Ember.Object({
        "id": "",
        "name": "Alle varslet/ikke varslet"
      }), new Ember.Object({
        "id": "no",
        "name": "Ikke varslet"
      }), new Ember.Object({
        "id": "yes",
        "name": "Varslet"
      })];
      data.years = [new Ember.Object({
        "id": "",
        "name": "Alle år"
      })];
      var years = new Date().getFullYear() - 2017;
      for (var year = 2017; year <= 2017 + years; year++) {
        data.years.push(new Ember.Object({
          "id": String(year),
          "name": String(year)
        }));
      }
      this.get('spinner').hide('main');
    }
  });
});