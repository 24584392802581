define("frontend/pods/components/crud-list/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    spinner: Ember.inject.service('spinner'),
    networkstatus: Ember.inject.service('network-status'),
    hasSelection: Ember.computed('data.@each._selected', function () {
      return this.get('data').isAny('_selected', true);
    }),
    selectedReports: Ember.computed('data.@each._selected', function () {
      return this.selected();
    }),
    canSelectAll: Ember.computed('hasSelection', 'data.[]', function () {
      var count = this.get('data').get('length');
      var selectionCount = this.selected().get('length');
      return count > 0 && selectionCount < count;
    }),
    shouldPaginate: Ember.computed('pages', function () {
      var pages = this.get('pages');
      return pages && pages.length > 1;
    }),
    isConnected: function isConnected() {
      return this.get('networkstatus').online;
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('pages', 0);
      this.set('currentPage', 1);
      this.set('pageSize', 30);
      this.set('isNotConnected', !this.isConnected());
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.$('.toolbar-filter').children().length == 0) {
        this.$('.toolbar-filter').hide();
      }
    },
    paginatedData: Ember.computed('data.[]', 'currentPage', function () {
      return this.paginate();
    }),
    selected: function selected() {
      return this.get('data').filterBy('_selected', true);
    },
    paginate: function paginate() {
      var pageSize = this.get('pageSize');
      var data = this.get('data');
      if (data.meta && data.meta.pagination) {
        var pageination = data.meta.pagination;
        var pages = Array.from(Array(pageination.pages + 1).keys());
        pages = pages.removeAt(0);
        this.set('pages', pages);
        this.set('currentPage', pageination.page);
        this.set('hasNextPage', pageination.page < pageination.pages);
        this.set('hasPrevPage', pageination.page > 1);
        return data;
      } else {
        if (pageSize > 1) {
          var currentPage = this.get('currentPage');
          var pageCount = Math.ceil(data.get('length') / pageSize);
          var start = currentPage * pageSize - pageSize;
          var end = Math.min(currentPage * pageSize, data.get('length'));
          var _pages = Array.from(Array(pageCount + 1).keys());
          _pages = _pages.removeAt(0);
          this.set('pages', _pages);
          this.set('hasNextPage', currentPage < pageCount);
          this.set('hasPrevPage', currentPage > 1);
          return data.slice(start, end);
        } else {
          return data;
        }
      }
    },
    refreshData: function refreshData() {
      var data = this.get('data');
      if (data.meta && data.meta.pagination) {
        data.query.page = this.get('currentPage');
        data.query.q = this.get('search_query');
        return data.update();
      } else {
        // Local data - do nothing.
        return new Ember.RSVP.Promise(function (resolve) {
          resolve();
        });
      }
    },
    doSearch: function doSearch(text) {
      var _this = this;
      this.get('spinner').show('main');
      this.set('search_query', text);
      this.set('currentPage', 1);
      this.refreshData().then(function () {
        _this.get('spinner').hide('main');
      }).catch(function (error) {
        console.error(error);
        this.get('spinner').hide('main');
      });
    },
    clearSearch: function clearSearch() {
      if (this.get('search_query') === '') {
        return; // no need to clear and make request to backend
      }
      this.doSearch('');
      this.$('input[type=text]').focus();
    },
    actions: {
      search: function search(text) {
        if (text === '' || text.length > 2) {
          Ember.run.debounce(this, this.doSearch, text, 1000, false);
        }
      },
      empty: function empty() {
        this.clearSearch();
      },
      delete: function _delete() {
        return this.get('onDelete')(this.selected());
      },
      selectNone: function selectNone() {
        this.get('paginatedData').setEach('_selected', false);
      },
      selectAll: function selectAll() {
        this.get('paginatedData').setEach('_selected', true);
      },
      proxy: function proxy(action) {
        return action(this.selected());
      },
      refresh: function refresh() {
        var _this2 = this;
        this.get('spinner').show('main');
        this.get('data').update().then(function () {
          _this2.get('spinner').hide('main');
        });
      },
      pageChanged: function pageChanged(page) {
        var _this3 = this;
        this.set('currentPage', page);
        this.get('spinner').show('main');
        this.refreshData().then(function () {
          _this3.get('spinner').hide('main');
        });
      },
      prevPage: function prevPage() {
        var _this4 = this;
        if (this.get('hasPrevPage')) {
          this.decrementProperty('currentPage');
          this.get('spinner').show('main');
          this.refreshData().then(function () {
            _this4.get('spinner').hide('main');
          });
        }
      },
      nextPage: function nextPage() {
        var _this5 = this;
        if (this.get('hasNextPage')) {
          this.incrementProperty('currentPage');
          this.get('spinner').show('main');
          this.refreshData().then(function () {
            _this5.get('spinner').hide('main');
          });
        }
      }
    }
  });
});