define("frontend/pods/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "APVG8FJt",
    "block": "{\"statements\":[[1,[26,[\"outlet\"]],false],[0,\"\\n\\n\"],[6,[\"ember-cli-spinner\"],null,[[\"id\",\"type\",\"height\",\"width\",\"bgColor\",\"color\"],[\"main\",\"wave\",\"80px\",\"100px\",\"rgba(0, 0, 0, 0.5)\",\"white\"]],{\"statements\":[[0,\"    \"],[11,\"span\",[]],[15,\"style\",\"color: #fff\"],[13],[0,\"Laster, vennligst vent…\"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/application/template.hbs"
    }
  });
});