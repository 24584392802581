define("frontend/pods/utils/light-model", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Object.extend({
    init: function init(store, data) {
      var _this = this;
      this._super();
      this.store = store;
      if (data && data['data']) {
        this.id = data['data']['id'];
        this.type = data['data']['type'];
        Object.keys(data['data']['attributes']).forEach(function (attr) {
          _this[attr] = data['data']['attributes'][attr];
        });
        Object.keys(data['data']['relationships']).forEach(function (attr) {
          _this[attr] = Ember.computed("_" + attr, function () {
            var _this2 = this;
            var rels = this.record['data']['relationships'][attr]['data'];
            if (rels instanceof Array) {
              var fetches = [];
              rels.forEach(function (rel) {
                fetches.push(_this2.store.lfindRecord(rel['type'], rel['id']));
              });
              return _emberData.default.PromiseArray.create({
                promise: Ember.RSVP.all(fetches).then(function (result) {
                  return Ember.ArrayProxy.create({
                    content: result
                  });
                })
              });
            } else {
              var promise;
              if (rels) {
                promise = this.store.lfindRecord(rels['type'], rels['id']);
              } else {
                promise = new Ember.RSVP.Promise(function (resolve) {
                  return resolve(null);
                });
              }
              return _emberData.default.PromiseObject.create({
                promise: promise
              });
            }
          });
        });
        // empty out attributes, could contain large amount of data such as
        // images so we don't want to store it twice.
        data['data']['attributes'] = [];
      }
      this.record = data;
    },
    getAllRelatedData: function getAllRelatedData(store, allowedModelTypes) {
      allowedModelTypes = allowedModelTypes || [];
      var relationships = this._relationships();
      var promises = [];
      var fetch = function fetch(data) {
        if (allowedModelTypes.includes(data['type'])) {
          promises.push(store.lfindRecord(data['type'], data['id']));
        }
      };
      for (var relName in relationships) {
        var relData = relationships[relName];
        var data = relData['data'];
        if (data == null) {
          continue;
        }
        if (data instanceof Array) {
          // hasMany?
          data.forEach(function (item) {
            fetch(item);
          });
        } else {
          // belongsTo
          fetch(data);
        }
      }
      return new Ember.RSVP.Promise(function (resolve) {
        var rv = [];
        Ember.RSVP.all(promises).then(function (result) {
          var relatedDataPromises = [];
          rv = rv.concat(result);
          result.forEach(function (record) {
            relatedDataPromises.push(record.getAllRelatedData(store, allowedModelTypes));
          });
          Ember.RSVP.all(relatedDataPromises).then(function (relatedData) {
            relatedData.forEach(function (records) {
              rv = rv.concat(records);
            });
            return resolve(rv);
          });
        });
      });
    },
    realModel: function realModel() {
      return this.store.findRecord(this.type, this.id);
    },
    destroyRecord: function destroyRecord() {
      return this.store.deleteRecordById(this.type, this.id);
    },
    _relationships: function _relationships() {
      if (this.record && this.record['data'] && this.record['data']['relationships']) {
        return this.record['data']['relationships'];
      } else {
        return {};
      }
    }
  });
});