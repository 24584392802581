define("frontend/pods/entity-type/model", ["exports", "ember-data", "frontend/pods/utils/models"], function (_exports, _emberData, _models) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.Model.extend(_models.default, {
    name: _emberData.default.attr('string'),
    modified: _emberData.default.attr('number'),
    deviations: _emberData.default.hasMany('deviationType'),
    favoriteDeviations: _emberData.default.hasMany('favoriteDeviation'),
    images: _emberData.default.hasMany('image'),
    imageIds: _emberData.default.attr('string'),
    // json
    status: _emberData.default.attr('number'),
    calculator: _emberData.default.attr('string'),
    // json

    image: Ember.computed('images.[]', {
      get: function get() {
        return this.get('images.firstObject');
      },
      set: function set(key, value) {
        this.set('images', []);
        this.get('images').pushObject(value);
        return value;
      }
    })
  });
});