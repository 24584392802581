define("frontend/pods/components/image-picker/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    classNames: ['image-picker'],
    store: Ember.inject.service('store'),
    resizer: Ember.inject.service('image-resize'),
    width: 1000,
    init: function init() {
      this._super.apply(this, arguments);
      this.resolveImage();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      this.resolveImage();
    },
    resolveImage: function resolveImage() {
      var _this = this;
      var image = this.get('image');
      if (image && typeof image.then === 'function') {
        this.set('image', null);
        image.then(function (image) {
          _this.set('image', image);
        }).catch(function () {
          _this.set('image', null);
        });
      } else {
        this.set('image', image);
      }
    },
    actions: {
      upload: function upload(event) {
        var _this2 = this;
        var file = event.target.files[0];
        if (file) {
          this.get('resizer').resizeImage(file, this.get('width')).then(function (imageData) {
            var image = _this2.get('image');
            if (!image || image.isRejected) {
              image = _this2.get('store').createRecord('iimage', {
                status: 1,
                legacy: 0
              });
            }
            image.set('file', imageData);
            image.set('isUpdated', true);
            image.set('legacy', 0);
            _this2.get('onChange')(image);
            _this2.$('input[type=file]').val('');
          });
        }
      },
      remove: function remove() {
        var image = this.get('image');
        this.set('image', null);
        this.$('input[type=file]').val('');
        if (this.get('onDelete')) {
          this.get('onDelete')(image);
        }
      },
      showUploadImage: function showUploadImage() {
        this.$('input[type=file]').click();
      }
    }
  });
});