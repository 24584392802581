define("frontend/pods/components/calculator-contactswing/component", ["exports", "frontend/pods/components/calculator-oneseatswing/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    init: function init() {
      this._super.apply(this, arguments);

      //let sharedInputs = this.get('sharedInputs');
      //this.get('inputs').addObject(sharedInputs['seatheight']);

      var outputs = this.get('outputs');

      // Any changes here also requires changes in models.py
      var output = outputs.findBy('code', '2442');
      output.set('code', '2442KoHu');
      output.set('constant', 0.3);
      var lastOutput = outputs.popObject();
      outputs.addObject(new Ember.Object({
        id: '2441KoHu',
        code: '2441KoHu',
        name: 'Avstand til senter',
        value: '',
        measurement1: new Ember.Object({
          name: 'målt',
          value: ''
        })
      }));
      outputs.addObject(lastOutput);
      this.get('inputs').findBy('id', 'seattype').set('options', [{
        value: 1,
        label: "Enkelt"
      }, {
        value: 3,
        label: "Stående dekk",
        selected: "selected"
      }, {
        value: 4,
        label: "Gruppe"
      }]);
      this.get('inputs').findBy('id', 'seattype').set('value', 3);
      outputs.findBy('code', '2420').set('code', '2420Fhu');
    },
    calculate: function calculate() {
      this._super.apply(this, arguments);
      this.setResultValue('2441StativKlaring', null);
      if (this.canCalc(['length'])) {
        var length = this.getInputValue('length');
        this.setResultValue('2441KoHu', length + 40);
        this.setResultValue('2441StativKlaring', length * 0.2 + 40);
      }
    }
  });
});