define("frontend/pods/utils/backend/adapter", ["exports", "ember-data", "frontend/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, {
    authorizer: 'authorizer:token',
    host: _environment.default.APP.jsonApiHost,
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super.apply(this, arguments);
      var parser = document.createElement('a');
      parser.href = url;
      var pathname = null;
      if (query && query.is_admin) {
        pathname = "/adminpanel" + "/" + parser.pathname; // extra / needed for IE
        pathname = pathname.replace("//", "/");
        return parser.protocol + "//" + parser.host + pathname;
      } else if (query && query.is_customer || modelName == 'contact-person' && requestType == 'updateRecord') {
        pathname = "/customerpanel" + "/" + parser.pathname; // extra / needed for IE
        pathname = pathname.replace("//", "/");
        return parser.protocol + "//" + parser.host + pathname;
      } else {
        return url;
      }
    }
  });
});