define("frontend/pods/components/report-preview/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "g1euhzkI",
    "block": "{\"statements\":[[6,[\"bs-modal-simple\"],null,[[\"class\",\"open\",\"title\",\"closeTitle\",\"submitTitle\",\"size\",\"fade\",\"backdrop\",\"onShow\",\"onSubmit\",\"onHidden\",\"renderInPlace\"],[\"report-preview\",[28,[\"show\"]],\"Forhåndsvisning av rapporter\",\"Lukk\",\"\",\"lg\",false,true,[33,[\"action\"],[[28,[null]],\"show\"],null],[33,[\"action\"],[[28,[null]],\"close\"],null],[33,[\"action\"],[[28,[null]],\"close\"],null],true]],{\"statements\":[[6,[\"if\"],[[28,[\"reports\"]]],null,{\"statements\":[[6,[\"bs-accordion\"],null,null,{\"statements\":[[6,[\"each\"],[[28,[\"previewItems\"]]],null,{\"statements\":[[6,[\"component\"],[[28,[\"acc\",\"item\"]]],[[\"value\",\"title\",\"onChange\"],[[28,[\"previewItem\",\"id\"]],[28,[\"previewItem\",\"name\"]],[28,[\"test\"]]]],{\"statements\":[[0,\"                    \"],[11,\"div\",[]],[15,\"class\",\"preview-container multiple\"],[13],[0,\"\\n                        \"],[11,\"iframe\",[]],[16,\"id\",[34,[\"iframe_report_\",[28,[\"previewItem\",\"id\"]]]]],[13],[14],[0,\"\\n                        \"],[11,\"img\",[]],[15,\"src\",\"\"],[16,\"onerror\",[33,[\"action\"],[[28,[null]],\"load_iframe\",[28,[\"previewItem\",\"id\"]],false],null],null],[13],[14],[0,\"\\n                    \"],[14],[0,\"\\n\"]],\"locals\":[]},null]],\"locals\":[\"previewItem\"]},null]],\"locals\":[\"acc\"]},null]],\"locals\":[]},{\"statements\":[[0,\"        \"],[11,\"div\",[]],[15,\"class\",\"preview-container single\"],[13],[0,\"\\n            \"],[11,\"iframe\",[]],[16,\"id\",[34,[\"single_iframe_report_\",[28,[\"previewItem\",\"id\"]]]]],[13],[14],[0,\"\\n            \"],[11,\"img\",[]],[15,\"src\",\"\"],[16,\"onerror\",[33,[\"action\"],[[28,[null]],\"load_iframe\",[28,[\"previewItem\",\"id\"]],true],null],null],[13],[14],[0,\"\\n        \"],[14],[0,\"\\n\"]],\"locals\":[]}]],\"locals\":[]},null]],\"locals\":[],\"named\":[],\"yields\":[],\"hasPartials\":false}",
    "meta": {
      "moduleName": "frontend/pods/components/report-preview/template.hbs"
    }
  });
});