define("frontend/pods/components/deviation-picker/component", ["exports", "frontend/pods/utils/search-dialog"], function (_exports, _searchDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend(_searchDialog.default, {
    classNames: ['picker', 'deviation-picker'],
    modelName: 'ideviationType',
    searchFields: ['name', 'code', 'searchWord'],
    equalHeightSelector: '.item-thumbnail h3',
    getData: function getData() {
      var _this = this;
      if (this.get('_data')) {
        return this.get('_data');
      }
      var data = new Ember.RSVP.Promise(function (resolve) {
        _this.get('reportEntity.entityType').then(function (entity) {
          var favIds = entity.hasMany('favoriteDeviations').ids();
          var devIds = entity.hasMany('deviations').ids();
          return Ember.RSVP.all([_this.get('store').lfindAllIds('ifavorite-deviation', favIds), _this.get('store').lfindAllIds('ideviation-type', devIds)]).then(function (items) {
            var favs = Ember.A();
            var dtPromises = [];
            if (items[0]) {
              items[0].forEach(function (favoriteDeviation) {
                var dt = favoriteDeviation.get('deviationType');
                dtPromises.push(dt);
                dt.then(function (dt) {
                  dt.set('_priority', favoriteDeviation.get('priority'));
                  favs.push(dt);
                });
              });
            }
            Ember.RSVP.all(dtPromises).then(function () {
              var deviations = items[1] || Ember.A();
              if (deviations.length == 0) {
                return resolve(_this._allEntities(favs));
              } else {
                return resolve(_this._mergeData(favs, deviations));
              }
            });
          });
        }).catch(function (error) {
          var message = "Could not get entity. Was it deleted in sync? Error: ".concat(error, "\"");
          Sentry.addBreadcrumb({
            category: 'deviation-picker',
            message: message,
            level: Sentry.Severity.Error
          });
          console.error(message);
          return resolve(_this._allEntities(Ember.A()));
        });
      });
      this.set('_data', data);
      return data;
    },
    sort: function sort(data) {
      return data.sortBy('_isNotFavorite', '_priority', 'code');
    },
    _allEntities: function _allEntities(favs) {
      var _this2 = this;
      return this.get('store').lfindAll('ideviationType').then(function (deviations) {
        return _this2._mergeData(favs, deviations);
      });
    },
    _mergeData: function _mergeData(favs, deviations) {
      favs.forEach(function (fav) {
        var duplicate = deviations.findBy('id', fav.get('id'));
        if (duplicate) {
          deviations.removeObject(duplicate);
        }
        fav.set('_isNotFavorite', false);
      });
      deviations.setEach('_isNotFavorite', true);
      return [].addObjects(favs).addObjects(deviations);
    },
    actions: {
      pick: function pick(item) {
        var _this3 = this;
        // convert light model to actual model
        item.realModel().then(function (deviation) {
          _this3.set('show', false);
          _this3.get('onPick')(deviation);
        });
      }
    }
  });
});