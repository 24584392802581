define("frontend/pods/ajax/service", ["exports", "frontend/config/environment", "ember-ajax/services/ajax"], function (_exports, _environment, _ajax) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _ajax.default.extend({
    session: Ember.inject.service(),
    host: _environment.default.APP.jsonApiHost,
    headers: Ember.computed('session.authToken', {
      get: function get() {
        var headers = {};
        var authToken = this.get('session.data.authenticated.token');
        if (authToken) {
          headers['Authorization'] = "Token " + authToken;
        }
        return headers;
      }
    }),
    jsonRequest: function jsonRequest(url, data) {
      var args = {
        method: 'POST',
        data: JSON.stringify(data),
        contentType: 'application/json'
      };
      return this.request(url, args);
    }
  });
});