define("frontend/pods/components/report-line/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.Component.extend({
    tagName: '',
    statusLineClass: '',
    statusLineText: '',
    _statusLineClass: Ember.computed('report.hasPendingLocalChanges', 'report.hasPendingServerChanges', 'report.startInspectionDate', 'report.stopInspectionDate', function () {
      var report = this.get('report');
      if (report.get('hasPendingServerChanges')) {
        this.set('statusLineText', 'Rapport kunne ikke synkroniseres da det er endringer på både sever og lokalt. Rediger, lagre og følg anvisningen.');
        this.set('statusLineClass', 'danger');
      } else if (report.get('hasPendingLocalChanges')) {
        this.set('statusLineText', 'Rapport har endringer som ikke er lagret på server. Rediger og lagre endringer.');
        this.set('statusLineClass', 'warning');
      } else if (report.get('startInspectionDate') && !report.get('stopInspectionDate')) {
        this.set('statusLineText', 'Rapport er ikke fullført. Rediger og fullfør rapport.');
        this.set('statusLineClass', 'warning');
      } else {
        this.set('statusLineText', null);
        this.set('statusLineClass', null);
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('_statusLineClass');
    }
  });
});